import React from 'react';
import { NavLink } from 'react-router-dom';
import { Menu, Icon, Button } from 'antd';

const AppMenu = ({ username, onLogoutClick }) => (
  <Menu
    // selectable={false}
    // className="app-menu"
    theme="dark"
    mode="horizontal"
    // defaultSelectedKeys={['2']}
    style={{ lineHeight: '64px' }}
  >
    <Menu.Item key="/clients">
      <NavLink to="/clients">
        <Icon type="user" />
        Clients
      </NavLink>
    </Menu.Item>
    <Menu.Item key="/daily">
      <NavLink to="/daily">
        <Icon type="calendar" />
        Daily
      </NavLink>
    </Menu.Item>
    {/* <Menu.Item key="/schedule">
      <NavLink to="/schedule">
        <Icon type="schedule" />
        Schedule
      </NavLink>
    </Menu.Item> */}
    <Menu.Item key="/overdue">
      <NavLink to="/overdue">
        <Icon type="warning" />
        Overdue
      </NavLink>
    </Menu.Item>
    <Menu.Item key="/logout" style={{ float: 'right' }}>
      <Button type="primary" style={{ marginLeft: 8 }} onClick={onLogoutClick}>Logout</Button>
    </Menu.Item>
    <Menu.Item key="/account" style={{ float: 'right' }}>
      <NavLink to="/account">
        <Icon type="user" />
        {username}
      </NavLink>
    </Menu.Item>
  </Menu>
);

export default AppMenu;
