import React from 'react';
import { Alert, Button, Card, Spin } from 'antd';

const ChangePasswordCard = ({
  processing, // Boolean: is currently processing.
  error, // An error message
  success, // A success message
  onChangePasswordClick,
}) => {
  const desc = (
    <div>
      <p>{error}</p>
      <Button type="default" onClick={onChangePasswordClick}>Try Again</Button>
    </div>
  );

  return (
    <Spin spinning={processing}>
      {success && <Alert message="Password Reset Email Sent" description={success} type="success" showIcon />}
      {error && <Alert message="Change Password Failed" description={desc} type="error" showIcon />}
      {!success && !error && (
        <Card>
          <p>To change your password click the below button.</p>
          <p><Button type="primary" onClick={onChangePasswordClick}>Change Password</Button></p>
        </Card>
      )}
    </Spin>
  );
};

export default ChangePasswordCard;
