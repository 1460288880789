import React from 'react';
import { Popover, Icon } from 'antd';

const SCREENING_NON_NEGATIVE = 'Non-Negative';
const SCREENING_NEGATIVE = 'Negative';
const SCREENING_MISSED = 'Missed';
const SCREENING_PENDING = 'Pending';
const SCREENING_NOT_NEEDED = 'Not Needed';

const ScreeningCell = (props) => {
  const {
    screeningStatus,
    showLabel,
  } = props;

  let content;
  let icon;

  switch (screeningStatus) {
    case SCREENING_NON_NEGATIVE:
      content = 'Non-Negative';
      icon = <Icon type="exclamation-circle" theme="twoTone" twoToneColor="orange" />;
      break;
    case SCREENING_NEGATIVE:
      content = 'Negative';
      icon = <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" />;
      break;
    case SCREENING_MISSED:
      content = 'Missed';
      icon = <Icon type="cross-circle" theme="twoTone" twoToneColor="red" />;
      break;
    case SCREENING_PENDING:
      content = 'Pending';
      icon = <Icon type="question-circle" />;
      break;
    case SCREENING_NOT_NEEDED:
      return null;
    default:
      throw new Error(`Unexpected screeningStatus: ${screeningStatus}`);
  }

  const label = showLabel ? (icon ? <><span>Sc</span><br /></> : <><span></span><br /></>) : null;

  return (
    <Popover title="Screening Result" content={content} trigger="click">
      {label}
      {icon}
    </Popover>
  );
};

export default ScreeningCell;
