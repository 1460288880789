import moment from 'moment';

import { postBatchPlan } from '../../../Common/api';

import {
  modalOpen,
  modalClose,
  modalSetField,
  modalSavingStart,
  modalSavingSuccess,
  modalSavingFailure,
} from './modal_actions';

export const BATCH_PLAN_MODAL_OPEN = 'BATCH_PLAN_MODAL_OPEN';
export const BATCH_PLAN_MODAL_CLOSE = 'BATCH_PLAN_MODAL_CLOSE';
export const BATCH_PLAN_MODAL_SET_FIELD = 'BATCH_PLAN_MODAL_SET_FIELD';
export const BATCH_PLAN_MODAL_SAVING_START = 'BATCH_PLAN_MODAL_SAVING_START';
export const BATCH_PLAN_MODAL_SAVING_SUCCESS = 'BATCH_PLAN_MODAL_SAVING_SUCCESS';
export const BATCH_PLAN_MODAL_SAVING_FAILURE = 'BATCH_PLAN_MODAL_SAVING_FAILURE';

export function batchPlanModalOpen(data, onSave) {
  const formState = {
    clientId: { value: data.client.id },
    clientName: { value: data.client.name },
    calendarStartDate: { value: data.calendarDate ? moment(data.calendarDate, 'YYYY-MM-DD') : null },
    calendarEndDate: { value: data.calendarDate ? moment(data.calendarDate, 'YYYY-MM-DD') : null },
    checkinRequired: { value: data.checkinRequired || false },
    checkinNotRequiredReason: { value: data.checkinNotRequiredReason },

    checkinOverrideTime: { value: data.checkinOverrideTime || false },
    checkinStartTime: { value: data.checkinStartTime ? moment(data.checkinStartTime, 'HH:mm') : null },
    checkinEndTime: { value: data.checkinEndTime ? moment(data.checkinEndTime, 'HH:mm') : null },
    sampleRequired: { value: data.sampleRequired || false },
    sampleStartTime: { value: data.sampleStartTime ? moment(data.sampleStartTime, 'HH:mm') : null },
    sampleEndTime: { value: data.sampleEndTime ? moment(data.sampleEndTime, 'HH:mm') : null },
    sampleInstructions: { value: data.sampleInstructions },
  };

  return modalOpen(BATCH_PLAN_MODAL_OPEN, formState, onSave);
}

export function batchPlanModalClose() {
  return modalClose(BATCH_PLAN_MODAL_CLOSE);
}

export function batchPlanModalSetField(formState) {
  return modalSetField(BATCH_PLAN_MODAL_SET_FIELD, formState);
}

export function batchPlanModalSavingStart() {
  return modalSavingStart(BATCH_PLAN_MODAL_SAVING_START);
}

export function batchPlanModalSavingSuccess() {
  return modalSavingSuccess(BATCH_PLAN_MODAL_SAVING_SUCCESS);
}

export function batchPlanModalSavingFailure(error) {
  return modalSavingFailure(BATCH_PLAN_MODAL_SAVING_FAILURE, 'Unable to save planning', error);
}

export function batchPlanModalSave(form) {
  return (dispatch, getState) => {
    const { batchPlanModal } = getState();
    const { onSave } = batchPlanModal;

    form.validateFields({ force: true }, (err, values) => {
      // If there are errors then cancel the save.
      // Any errors will be displayed per field on the form.
      if (err) {
        return;
      }

      const calendarStartDate = values.calendarStartDate.format('YYYY-MM-DD');
      const calendarEndDate = values.calendarEndDate.format('YYYY-MM-DD');

      const {
        clientId,
        checkinRequired,
        checkinOverrideTime,
        sampleRequired,
      } = values;

      const checkinNotRequiredReason = (checkinRequired ? null : values.checkinNotRequiredReason);
      const checkinStartTime = (checkinOverrideTime ? values.checkinStartTime.format('HH:mm') : null);
      const checkinEndTime = (checkinOverrideTime ? values.checkinEndTime.format('HH:mm') : null);
      const sampleStartTime = (sampleRequired ? values.sampleStartTime.format('HH:mm') : null);
      const sampleEndTime = (sampleRequired ? values.sampleEndTime.format('HH:mm') : null);
      const sampleInstructions = (sampleRequired ? values.sampleInstructions : null);

      const body = {
        calendarStartDate,
        calendarEndDate,
        checkinRequired,
        checkinNotRequiredReason,
        checkinOverrideTime,
        checkinStartTime,
        checkinEndTime,
        sampleRequired,
        sampleStartTime,
        sampleEndTime,
        sampleInstructions,
      };

      dispatch(batchPlanModalSavingStart());

      postBatchPlan({ clientId }, body)
        .then((obj) => {
          dispatch(batchPlanModalSavingSuccess(obj));
          dispatch(batchPlanModalClose());
          onSave();
        })
        .catch((apiErr) => {
          dispatch(batchPlanModalSavingFailure(apiErr));
        });
    });
  };
}
