import { connect } from 'react-redux';

import Overdue from './Overdue';

import {
  setOverdueSearchText,
  setOverduePage,
  setOverduePageSize,
  setOverdueDate,
  nextOverdueDate,
  prevOverdueDate,
  getOverdue,
} from '../redux/actions/overdueActions';

import { breachModalOpen } from '../redux/actions/breachActions';
import { checkinModalOpen } from '../redux/actions/checkinActions';
import { forensicModalOpen } from '../redux/actions/forensicActions';
import { planningModalOpen } from '../redux/actions/planningActions';
import { screeningModalOpen } from '../redux/actions/screeningActions';
import { batchPlanModalOpen } from '../redux/actions/batchPlanActions';

const mapStateToProps = state => ({
  ...state.overdue,
});

const mapDispatchToProps = dispatch => ({
  onPageChange(page) {
    dispatch(setOverduePage(page));
    dispatch(getOverdue());
  },
  onPageSizeChange(_page, pageSize) {
    dispatch(setOverduePageSize(pageSize));
    dispatch(getOverdue());
  },
  onSearchChange: (e) => {
    dispatch(setOverdueSearchText(e.target.value));
  },
  onSearch() {
    dispatch(getOverdue());
  },
  onActionClick(key, record) {
    const onSave = () => {
      dispatch(getOverdue());
    };

    switch (key) {
      case 'planning':
        dispatch(planningModalOpen(record, onSave));
        break;
      case 'batchplan':
        dispatch(batchPlanModalOpen(record, onSave));
        break;
      case 'checkin':
        dispatch(checkinModalOpen(record, onSave));
        break;
      case 'screening':
        dispatch(screeningModalOpen(record, onSave));
        break;
      case 'forensic':
        dispatch(forensicModalOpen(record, onSave));
        break;
      case 'breach':
        dispatch(breachModalOpen(record, onSave));
        break;
      default:
        throw new Error(`Unexpected onActionClick key [${key}]`);
    }
  },

  onDateChange(_moment, date) {
    dispatch(setOverdueDate(date));
    dispatch(getOverdue());
  },
  onDateNextClick() {
    dispatch(nextOverdueDate());
    dispatch(getOverdue());
  },
  onDatePrevClick() {
    dispatch(prevOverdueDate());
    dispatch(getOverdue());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Overdue);
