import React from 'react';
import { Col, Row } from 'antd';

import Page from '../Common/Page';
import CleanCard from './CleanCard';
import CheckinCard from './CheckinCard';
import ContactCard from './ContactCard';

const HomePage = ({
  onCheckinClick,
  checkinRecord,
  checkinLoading,
  checkinError,
  cleanDate,
  cleanDays,
  cleanDaysError,
  cleanDaysProcessing,
}) => (
  <Page title="Home">
    <Row>
      <Col xs={24} sm={18} md={12} lg={8} xl={6}>
        <CheckinCard
          onCheckinClick={onCheckinClick}
          record={checkinRecord}
          loading={checkinLoading}
          error={checkinError}
        />
      </Col>
    </Row>
    <Row className="top-buffer">
      <Col xs={24} sm={18} md={12} lg={8} xl={6}>
        <CleanCard
          cleanDate={cleanDate}
          cleanDays={cleanDays}
          cleanDaysError={cleanDaysError}
          cleanDaysProcessing={cleanDaysProcessing}
        />
      </Col>
    </Row>
    <Row className="top-buffer">
      <Col xs={24} sm={18} md={12} lg={8} xl={6}>
        <ContactCard />
      </Col>
    </Row>
  </Page>
);

export default HomePage;
