import React from 'react';
// import Amplify from 'aws-amplify';
// import { withAuthenticator } from 'aws-amplify-react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import { Layout } from 'antd';


import logo from '../Common/rr-monitoring-logo-green.png';

import AppMenu from './AppMenu';
import ClientListingPage from './ClientListing/ClientListingPage';
import DailyPage from './Daily/DailyPage';

// import ClientPage from './Client/ClientPage';
// import AppMenu from './AppMenu';

import BreachController from './Modals/BreachModal/BreachController';
import CheckinController from './Modals/CheckinModal/CheckinController';
import ForensicController from './Modals/ForensicModal/ForensicController';
import PlanningController from './Modals/PlanningModal/PlanningController';
import ScreeningController from './Modals/ScreeningModal/ScreeningController';
import BatchPlanController from './Modals/BatchPlanModal/BatchPlanController';
import ClientPage from './Client/ClientPage';
import OverduePage from './Overdue/OverduePage';


// Amplify.configure({
//   Auth: {

//     // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
//     // identityPoolId: '',

//     // REQUIRED - Amazon Cognito Region
//     region: 'ca-central-1',

//     // OPTIONAL - Amazon Cognito Federated Identity Pool Region
//     // Required only if it's different from Amazon Cognito Region
//     // identityPoolRegion: 'XX-XXXX-X',

//     // OPTIONAL - Amazon Cognito User Pool ID
//     userPoolId: 'ca-central-1_k7hiRzvP8',

//     // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
//     userPoolWebClientId: 'vanfq85m21sqaehcuruhkt9ol',

//     // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
//     mandatorySignIn: true,

//     // OPTIONAL - Configuration for cookie storage
//     // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
//     cookieStorage: {
//       // REQUIRED - Cookie domain (only required if cookieStorage is provided)
//       domain: '.yourdomain.com',
//       // OPTIONAL - Cookie path
//       path: '/',
//       // OPTIONAL - Cookie expiration in days
//       expires: 365,
//       // OPTIONAL - Cookie secure flag
//       // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
//       secure: true,
//     },

//     // OPTIONAL - customized storage object
//     // storage: new MyStorage(),

//     // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
//     // authenticationFlowType: 'USER_PASSWORD_AUTH'
//   },
// });
const version = process.env.REACT_APP_BUILD_VERSION;
/*

move the logo to src/commons


later in page

<img src={logo} />


const App = ({ authData, onLogoutClick }) => (
*/
const App = ({ username, onLogoutClick }) => (
  <Router>
    <Layout className="container">
      <Layout.Header>
        <img className="logo" alt="rr-monitoring" height="100%" width="100%" vertical-align="top" src={logo} />
        <AppMenu username={username || 'Unauthorized'} onLogoutClick={onLogoutClick} />
      </Layout.Header>
      <Layout.Content className="app-content">
        <BreachController />
        <CheckinController />
        <ForensicController />
        <PlanningController />
        <ScreeningController />
        <BatchPlanController />
        <Switch>
          <Route exact path="/clients/:id" component={ClientPage} />
          <Route exact path="/clients" component={ClientListingPage} />
          <Route exact path="/daily" component={DailyPage} />
          <Route exact path="/overdue" component={OverduePage} />
          <Redirect exact from="/" to="/daily" />
        </Switch>
      </Layout.Content>
      <Layout.Footer>
        RR Monitoring © 2018 Developed by AscenTech, Version: {version}
      </Layout.Footer>
    </Layout>
  </Router>
);

// console.log(withAuthenticator);

// export default withAuthenticator(App);
// export default App;
export default props => (<App {...props} />);
