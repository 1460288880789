import {
  FORENSIC_MODAL_OPEN,
  FORENSIC_MODAL_CLOSE,
  FORENSIC_MODAL_SAVING_START,
  FORENSIC_MODAL_SAVING_SUCCESS,
  FORENSIC_MODAL_SAVING_FAILURE,
  FORENSIC_MODAL_SET_FIELD,
} from '../actions/forensicActions';

const initialState = {
  formState: { },
  isProcessing: false,
  error: null,
  show: false,
  canSave: false,
  canCancel: true,
};

export default function forensicModal(state = initialState, action) {
  switch (action.type) {
    case FORENSIC_MODAL_OPEN:
    case FORENSIC_MODAL_CLOSE:
    case FORENSIC_MODAL_SAVING_START:
    case FORENSIC_MODAL_SAVING_SUCCESS:
    case FORENSIC_MODAL_SAVING_FAILURE:
      return Object.assign({}, state, { ...action.payload });
    case FORENSIC_MODAL_SET_FIELD:
      return Object.assign({}, state, {
        formState: Object.assign({}, state.formState, { ...action.payload.formState }),
      });
    default:
      return state;
  }
}
