export function modalOpen(type, formState, onSave) {
  return {
    type,
    payload: {
      isProcessing: false,
      show: true,
      error: null,
      canSave: true,
      canCancel: true,
      formState,
      onSave,
    },
  };
}

export function modalClose(type) {
  return {
    type,
    payload: {
      show: false,
    },
  };
}

export function modalSetField(type, formState) {
  return {
    type,
    payload: {
      formState,
    },
  };
}

export function modalSavingStart(type) {
  return {
    type,
    payload: {
      isProcessing: true,
      canSave: false,
      canCancel: false,
      error: null,
    },
  };
}

export function modalSavingSuccess(type) {
  return {
    type,
    payload: {
      isProcessing: false,
      canSave: true,
      canCancel: true,
      error: null,
    },
  };
}

export function modalSavingFailure(type, message, error) {
  console.log('DA ERROR IS HERE', error.message);
  return {
    type,
    payload: {
      isProcessing: false,
      canSave: true,
      canCancel: true,
      // added error.body for api.js if err.reportable
      error: { message, description: error.body || error.message },
    },
  };
}

// export function modalSave(form) {
//   return (dispatch, getState) => {
//     const { breachModal } = getState();
//     const { clientId, onSave } = breachModal;

//     form.validateFields({ force: true }, (err, values) => {
//       // If there are errors then cancel the save.
//       // Any errors will be displayed per field on the form.
//       if (err) {
//         return;
//       }

//       const calendarDate = values.calendarDate.format('YYYY-MM-DD');

//       const {
//         breachOccurred,
//         breachType,
//         breachNote,
//       } = values;

//       dispatch(breachModalSavingStart());

//       postBreach({ clientId, calendarDate }, { breachOccurred, breachType, breachNote })
//         .then((obj) => {
//           dispatch(breachModalSavingSuccess(obj));
//           dispatch(breachModalClose());
//           onSave();
//         })
//         .catch((apiErr) => {
//           dispatch(breachModalSavingFailure(apiErr));
//         });
//     });
//   };
// }
