import React from 'react';
import { Col, Row } from 'antd';

import Page from '../Common/Page';
import PhoneCard from './PhoneCard';
import AddressCard from './AddressCard';

const ContactPage = ({
  phone,
  addressLine1,
  addressLine2,
  addressLine3,
  mapUrl,
}) => (
  <Page title="Contact">
    <Row>
      <Col xs={24} sm={18} md={12} lg={8} xl={6}>
        <PhoneCard phone={phone} />
      </Col>
    </Row>
    <Row className="top-buffer">
      <Col xs={24} sm={18} md={12} lg={8} xl={6}>
        <AddressCard
          addressLine1={addressLine1}
          addressLine2={addressLine2}
          addressLine3={addressLine3}
          mapUrl={mapUrl}
        />
      </Col>
    </Row>
  </Page>
);

export default ContactPage;
