import React from 'react';
import { DatePicker, Form, Input, Switch, Button, Select, Row, Col } from 'antd';

const BreachForm = (props) => {
  const { canSave, canCancel, onSave, onCancel, isProcessing, form } = props;
  const { getFieldDecorator } = form;

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  const { formState: { breachOccurred: { value: breachOccurred } } } = props;

  return (
    <Form layout="vertical">
      <Form.Item label="Client" {...formItemLayout}>
        {getFieldDecorator('clientName', {
        })(
          <Input disabled />,
        )}
      </Form.Item>
      <Form.Item label="Client Id" {...formItemLayout} style={{ display: 'none' }}>
        {getFieldDecorator('clientId', {
        })(
          <Input disabled />,
        )}
      </Form.Item>
      <Form.Item label="Date" {...formItemLayout}>
        {getFieldDecorator('calendarDate', {
        })(
          <DatePicker disabled />,
        )}
      </Form.Item>
      <Form.Item label="Breach Occurred" {...formItemLayout}>
        {getFieldDecorator('breachOccurred', {
          valuePropName: 'checked',
        })(
          <Switch />,
        )}
      </Form.Item>
      <Form.Item label="Breach Type" {...formItemLayout}>
        {getFieldDecorator('breachType', {
          rules: [{
            required: breachOccurred,
            message: 'Breach Type must be specified if Breach Occurred.',
          }],
        })(
          <Select disabled={!breachOccurred}>
            <Select.Option value="Yellow">Yellow</Select.Option>
            <Select.Option value="Red">Red</Select.Option>
          </Select>,
        )}
      </Form.Item>
      <Form.Item label="Breach Note" {...formItemLayout}>
        {getFieldDecorator('breachNote', {
          rules: [{
            required: breachOccurred,
            message: 'Breach Note must be specified if Breach Occurred.',
          }],
        })(
          <Input.TextArea rows={8} disabled={!breachOccurred} />,
        )}
      </Form.Item>
      <Row>
        <Col span={24} style={{ textAlign: 'right' }}>
          <Button
            icon="rollback"
            onClick={onCancel}
            disabled={!canCancel}
          >
            Cancel
          </Button>
          <Button
            style={{ marginLeft: 8 }}
            type="primary"
            icon="save"
            loading={isProcessing}
            onClick={() => onSave(props.form)}
            disabled={!canSave}
          >
            Save
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default Form.create({
  name: 'BreachForm',
  mapPropsToFields(props) {
    return {
      clientId: Form.createFormField(props.formState.clientId),
      clientName: Form.createFormField(props.formState.clientName),
      calendarDate: Form.createFormField(props.formState.calendarDate),
      breachOccurred: Form.createFormField(props.formState.breachOccurred),
      breachType: Form.createFormField(props.formState.breachType),
      breachNote: Form.createFormField(props.formState.breachNote),
    };
  },
  onFieldsChange(props, formState) {
    props.onFieldChange(formState);
  },
})(BreachForm);
