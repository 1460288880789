import moment from 'moment';

import { postScreening } from '../../../Common/api';

import {
  modalOpen,
  modalClose,
  modalSetField,
  modalSavingStart,
  modalSavingSuccess,
  modalSavingFailure,
} from './modal_actions';

export const SCREENING_MODAL_OPEN = 'SCREENING_MODAL_OPEN';
export const SCREENING_MODAL_CLOSE = 'SCREENING_MODAL_CLOSE';
export const SCREENING_MODAL_SET_FIELD = 'SCREENING_MODAL_SET_FIELD';
export const SCREENING_MODAL_SAVING_START = 'SCREENING_MODAL_SAVING_START';
export const SCREENING_MODAL_SAVING_SUCCESS = 'SCREENING_MODAL_SAVING_SUCCESS';
export const SCREENING_MODAL_SAVING_FAILURE = 'SCREENING_MODAL_SAVING_FAILURE';

export function screeningModalOpen(data, onSave) {
  const formState = {
    clientId: { value: data.client.id },
    clientName: { value: data.client.name },
    calendarDate: { value: moment(data.calendarDate, 'YYYY-MM-DD') },
    sampleRequired: { value: data.sampleRequired },
    screeningResult: { value: data.screeningResult },
  };

  return modalOpen(SCREENING_MODAL_OPEN, formState, onSave);
}

export function screeningModalClose() {
  return modalClose(SCREENING_MODAL_CLOSE);
}

export function screeningModalSetField(formState) {
  return modalSetField(SCREENING_MODAL_SET_FIELD, formState);
}

export function screeningModalSavingStart() {
  return modalSavingStart(SCREENING_MODAL_SAVING_START);
}

export function screeningModalSavingSuccess() {
  return modalSavingSuccess(SCREENING_MODAL_SAVING_SUCCESS);
}

export function screeningModalSavingFailure(error) {
  return modalSavingFailure(SCREENING_MODAL_SAVING_FAILURE, 'Unable to save screening', error);
}

export function screeningModalSave(form) {
  return (dispatch, getState) => {
    const { screeningModal } = getState();
    const { onSave } = screeningModal;

    form.validateFields({ force: true }, (err, values) => {
      // If there are errors then cancel the save.
      // Any errors will be displayed per field on the form.
      if (err) {
        return;
      }

      const calendarDate = values.calendarDate.format('YYYY-MM-DD');

      const {
        clientId,
        // screeningResult,
      } = values;

      const screeningResult = values.screeningResult === 'NULL' ? null : values.screeningResult;

      dispatch(screeningModalSavingStart());

      postScreening({ clientId, calendarDate }, { screeningResult })
        .then((obj) => {
          dispatch(screeningModalSavingSuccess(obj));
          dispatch(screeningModalClose());
          onSave();
        })
        .catch((apiErr) => {
          dispatch(screeningModalSavingFailure(apiErr));
        });
    });
  };
}
