import moment from 'moment';

import { postForensic } from '../../../Common/api';

import {
  modalOpen,
  modalClose,
  modalSetField,
  modalSavingStart,
  modalSavingSuccess,
  modalSavingFailure,
} from './modal_actions';

export const FORENSIC_MODAL_OPEN = 'FORENSIC_MODAL_OPEN';
export const FORENSIC_MODAL_CLOSE = 'FORENSIC_MODAL_CLOSE';
export const FORENSIC_MODAL_SET_FIELD = 'FORENSIC_MODAL_SET_FIELD';
export const FORENSIC_MODAL_SAVING_START = 'FORENSIC_MODAL_SAVING_START';
export const FORENSIC_MODAL_SAVING_SUCCESS = 'FORENSIC_MODAL_SAVING_SUCCESS';
export const FORENSIC_MODAL_SAVING_FAILURE = 'FORENSIC_MODAL_SAVING_FAILURE';

export function forensicModalOpen(data, onSave) {
  const formState = {
    clientId: { value: data.client.id },
    clientName: { value: data.client.name },
    calendarDate: { value: moment(data.calendarDate, 'YYYY-MM-DD') },
    screeningResult: { value: data.screeningResult },
    forensicRequired: { value: data.forensicRequired },
    forensicResult: { value: data.forensicResult },
  };

  return modalOpen(FORENSIC_MODAL_OPEN, formState, onSave);
}

export function forensicModalClose() {
  return modalClose(FORENSIC_MODAL_CLOSE);
}

export function forensicModalSetField(formState) {
  return modalSetField(FORENSIC_MODAL_SET_FIELD, formState);
}

export function forensicModalSavingStart() {
  return modalSavingStart(FORENSIC_MODAL_SAVING_START);
}

export function forensicModalSavingSuccess() {
  return modalSavingSuccess(FORENSIC_MODAL_SAVING_SUCCESS);
}

export function forensicModalSavingFailure(error) {
  return modalSavingFailure(FORENSIC_MODAL_SAVING_FAILURE, 'Unable to save forensic', error);
}

export function forensicModalSave(form) {
  return (dispatch, getState) => {
    const { forensicModal } = getState();
    const { onSave } = forensicModal;

    form.validateFields({ force: true }, (err, values) => {
      // If there are errors then cancel the save.
      // Any errors will be displayed per field on the form.
      if (err) {
        return;
      }

      const calendarDate = values.calendarDate.format('YYYY-MM-DD');

      const {
        clientId,
        forensicRequired,

      } = values;

      const forensicResult = values.forensicRequired ? (values.forensicResult === "NULL" ? null : values.forensicResult) : null;

      dispatch(forensicModalSavingStart());

      postForensic({ clientId, calendarDate }, { forensicRequired, forensicResult })
        .then((obj) => {
          console.log('GOOD API', obj);
          dispatch(forensicModalSavingSuccess(obj));
          dispatch(forensicModalClose());
          onSave();
        })
        .catch((fetchErr) => {
          console.log('BAD API', fetchErr);
          dispatch(forensicModalSavingFailure(fetchErr));
        });
    });
  };
}
