import React from 'react';
import { Popover, Icon } from 'antd';

const BREACH_TYPE_RED = 'Red';
const BREACH_TYPE_YELLOW = 'Yellow';

const BreachCell = (props) => {
  const {
    breachOccurred,
    breachType,
    showLabel,
  } = props;

  let content;
  let icon;

  if (!breachOccurred) {
    return null;
  }

  switch (breachType) {
    case BREACH_TYPE_YELLOW:
      content = breachType;
      icon = <Icon type="exclamation-circle" theme="twoTone" twoToneColor="orange" />;
      break;
    case BREACH_TYPE_RED:
      content = breachType;
      icon = <Icon type="exclamation-circle" theme="twoTone" twoToneColor="red" />;
      break;
    default:
      throw new Error(`Unexpected breachType: ${breachType}`);
  }

  const label = showLabel ? (icon ? <><span>Br</span><br /></> : <><span></span><br /></>) : null;

  return (
    <Popover title="Breach" content={content} trigger="click">
      {label}
      {icon}
    </Popover>
  );
};

export default BreachCell;
