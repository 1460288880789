import React from 'react';
import { Alert, Card, Spin } from 'antd';
import moment from 'moment';

const CleanCard = ({
  cleanDate,
  cleanDays,
  cleanDaysError,
  cleanDaysProcessing,
}) => {
  let content;

  if (cleanDaysError) {
    content = <Alert type="success" message="Error" description={cleanDaysError} />;
  } else if (cleanDate) {
    content = (
      <div>
        <p>RR Monitoring documentation continues to support your stated last use of alchohol/drugs of compulsion as {moment(cleanDate).format('MMMM Do YYYY')} ({cleanDays} days).</p>
      </div>);
  }
  return (
    <Spin spinning={cleanDaysProcessing} delay={500}>
      <Alert type="success" description={content} />
    </Spin>
  );
};

export default CleanCard;
