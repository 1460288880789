import React from 'react';
import { Tooltip, Progress } from 'antd';

const ClientStat = ({ good, bad, pending, header, units, goodTip, badTip, pendingTip }) => {
  const total = good + bad + pending;
  const percent = ((good + bad) * 100.00) / total;
  const successPercent = ((good) * 100.00) / total;
  const title = `${goodTip}: ${good}<br/> ${badTip}: ${bad}, ${pendingTip}: ${pending}`;

  const d = (
    <table>
      <tr>
        <td>{goodTip}</td>
        <td>{good}</td>
      </tr>
      <tr>
        <td>{badTip}</td>
        <td>{bad}</td>
      </tr>
      <tr>
        <td>{pendingTip}</td>
        <td>{pending}</td>
      </tr>
      <tr>
        <td>Total</td>
        <td>{total}</td>
      </tr>
    </table>
  );
  return (
    <>
      <Tooltip title={d}>
        <Progress width={ 64 } type="dashboard" strokeColor="red" successPercent={successPercent} percent={percent} format={(p, s) => `${bad}/${total}`} />
      </Tooltip>
      <br />
      {header}
      <br />
      <span style={{ fontSize: 'smaller' }}>{units}</span>
    </>
  );
};

export default ClientStat;
