import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Client from './Client';
import {
  clientSetField,
  clientSave,
  clientDiscard,
  clientScheduleSetDate,
  clientScheduleSetType,
  clientScheduleLoad,
  clientScheduleNextMonth,
  clientSchedulePrevMonth,
} from '../redux/actions/clientActions';

import { breachModalOpen } from '../redux/actions/breachActions';
import { checkinModalOpen } from '../redux/actions/checkinActions';
import { forensicModalOpen } from '../redux/actions/forensicActions';
import { screeningModalOpen } from '../redux/actions/screeningActions';
import { planningModalOpen } from '../redux/actions/planningActions';
import { batchPlanModalOpen } from '../redux/actions/batchPlanActions';

const mapStateToProps = state => ({
  ...state.client,
});

const mapDispatchToProps = dispatch => ({
  onFieldChange(formState) {
    dispatch(clientSetField(formState));
  },
  onCancelClick: (clientId) => {
    dispatch(clientDiscard(clientId));
  },
  onSaveClick: (form) => {
    dispatch(clientSave(form));
  },
  onDateChange: (mom) => {
    dispatch(clientScheduleSetDate(mom));
    dispatch(clientScheduleLoad());
  },
  onCalendarTypeChange: (calendarType) => {
    dispatch(clientScheduleSetType(calendarType));
    // dispatch(clientScheduleLoad());
  },
  onMonthNextClick() {
    dispatch(clientScheduleNextMonth());
    dispatch(clientScheduleLoad());
  },
  onMonthPrevClick() {
    dispatch(clientSchedulePrevMonth());
    dispatch(clientScheduleLoad());
  },
  onActionClick(key, record) {
    console.log('onActionClick', key, record);
    const onSave = () => {
      dispatch(clientScheduleLoad());
    };

    switch (key) {
      case 'schedule':
        dispatch(planningModalOpen(record, onSave));
        break;
      case 'batchplan':
        dispatch(batchPlanModalOpen(record, onSave));
        break;
      case 'checkin':
        dispatch(checkinModalOpen(record, onSave));
        break;
      case 'screening':
        dispatch(screeningModalOpen(record, onSave));
        break;
      case 'forensic':
        dispatch(forensicModalOpen(record, onSave));
        break;
      case 'breach':
        dispatch(breachModalOpen(record, onSave));
        break;
      default:
        throw new Error(`Unexpected onActionClick key [${key}]`);
    }
  },
  onTabChange(e) {
    const tab = e.target.value;
    console.log('Tab', tab);
  },
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Client),
);
