import moment from 'moment';

import {
  getSchedule as blah,
  getPlanningSchedule,
  listClients,
  postBreach,
  // upsertClient,
  // getClient,
} from '../../../Common/api';

// Special action type that is used to specify that an action was cancelled.
export const CANCEL_ACTION = 'CANCEL_ACTION';


// ///////////////////////////////////////////////////////////////////////////////////////
// SCHEDULE
// ///////////////////////////////////////////////////////////////////////////////////////

export const SET_SCHEDULE_SEARCH_TEXT = 'SET_SCHEDULE_SEARCH_TEXT';
export function setScheduleSearchText(text) {
  return { type: SET_SCHEDULE_SEARCH_TEXT, payload: text };
}

export const SET_SCHEDULE_DATE = 'SET_SCHEDULE_DATE';
export function setScheduleDate(date) {
  return { type: SET_SCHEDULE_DATE, payload: date };
}

export const NEXT_SCHEDULE_DATE = 'NEXT_SCHEDULE_DATE';
export function nextScheduleDate() {
  return { type: NEXT_SCHEDULE_DATE };
}

export const PREV_SCHEDULE_DATE = 'PREV_SCHEDULE_DATE';
export function prevScheduleDate() {
  return { type: PREV_SCHEDULE_DATE };
}

export const SET_SCHEDULE_PAGE = 'SET_SCHEDULE_PAGE';
export function setSchedulePage(offset) {
  return { type: SET_SCHEDULE_PAGE, payload: { offset } };
}

export const REQUEST_SCHEDULE = 'REQUEST_SCHEDULE';
export function requestSchedule() {
  return { type: REQUEST_SCHEDULE };
}

export const RECEIVE_SCHEDULE = 'RECEIVE_SCHEDULE';
export function receiveSchedule(error, items, count) {
  return { type: RECEIVE_SCHEDULE, payload: { error, items, count } };
}

export function getSchedule() {
  return (dispatch, getState) => {
    dispatch(requestSchedule());

    const {
      calendarDate,
      searchText,
      limit,
      offset,
    } = getState().schedule;

    blah(calendarDate, { search: searchText, limit, offset })
      .then((obj) => {
        dispatch(receiveSchedule(false, obj.rows, obj.count));
      })
      .catch((err) => {
        dispatch(receiveSchedule(err));
      });
  };
}


// ///////////////////////////////////////////////////////////////////////////////////////
// PLANNER
// ///////////////////////////////////////////////////////////////////////////////////////
export const SET_PLANNER_SEARCH_TEXT = 'SET_PLANNER_SEARCH_TEXT';
export function setPlannerSearchText(text) {
  return { type: SET_PLANNER_SEARCH_TEXT, payload: text };
}

export const SET_PLANNER_DATE = 'SET_PLANNER_DATE';
export function setPlannerDate(date) {
  return { type: SET_PLANNER_DATE, payload: date };
}

export const NEXT_PLANNER_DATE = 'NEXT_PLANNER_DATE';
export function nextPlannerDate() {
  return { type: NEXT_PLANNER_DATE };
}

export const PREV_PLANNER_DATE = 'PREV_PLANNER_DATE';
export function prevPlannerDate() {
  return { type: PREV_PLANNER_DATE };
}

export const SET_PLANNER_PAGE = 'SET_PLANNER_PAGE';
export function setPlannerPage(offset) {
  return { type: SET_PLANNER_PAGE, payload: { offset } };
}

export const REQUEST_PLANNER = 'REQUEST_PLANNER';
export function requestPlanner() {
  return { type: REQUEST_PLANNER };
}

export const RECEIVE_PLANNER = 'RECEIVE_PLANNER';
export function receivePlanner(error, items, count) {
  return { type: RECEIVE_PLANNER, payload: { error, items, count } };
}

export function getPlanner() {
  return (dispatch, getState) => {
    dispatch(requestPlanner());

    const {
      calendarDate,
      searchText,
      limit,
      offset,
    } = getState().planner;

    const startDate = calendarDate;
    const endDate = moment(startDate).add(7, 'days').format('YYYY-MM-DD');

    getPlanningSchedule(calendarDate, { search: searchText, limit, offset, startDate, endDate })
      .then((obj) => {
        dispatch(receivePlanner(false, obj.rows, obj.count));
      })
      .catch((err) => {
        dispatch(receivePlanner(err));
      });
  };
}
