/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { Form, Row, Col, Tabs, Card, Icon, Radio } from 'antd';
import { HashRouter, Link, Switch, Route } from 'react-router-dom';

import ClientForm from './ClientForm';
import ClientCalendar from './ClientCalendar';
import ClientDashboard from './ClientDashboard';
import BackButton from '../Components/BackButton';
import HashRoute from './HashRoute';
import ClientScheduleTable from './ClientScheduleTable';
import ClientStat from './ClientStat';
// import RadioGroup from 'antd/lib/radio/group';

const { TabPane } = Tabs;

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

const Client = (props) => {
  const { form, dateRender, onDateChange, onMonthPrevClick, onMonthNextClick, onActionClick, onTabChange, location, history } = props;
  const { getFieldDecorator } = form;
  const { hash } = location;

  const onTabChange2 = (e) => {
    history.replace(e.target.value);
    if (e.target.value === '#calendar' || e.target.value === '#listing') {
      onDateChange(props.date);
    }
  };

  let title = '';

  if (props.formState.lastName && props.formState.lastName.value) {
    title = (`${props.formState.lastName.value}, ${props.formState.firstName.value}`);
  } else {
    title = 'New Client';
  }

  // {/* <Row>
  //       <Col>
  //         <BackButton style={{ marginRight: 16 }} />
  //         {title}
  //         {/* <Button>Prev</Button> */}
  // {/* <Button>Next</Button> */ }
  //       </Col >
  //     </Row > * /}
  return (
    <>

      <Form layout="inline" style={{ padding: 8, backgroundColor: '#f0f2f5', marginBottom: 16 }}>
        <Form.Item>
          <BackButton />
          {/* <Link to="/clients/new"><Button type="primary" icon="user-add">New Client</Button></Link> */}
        </Form.Item>
        <Form.Item>
          {title}
          {/* <Input.Search value={searchText} onChange={onSearchChange} onSearch={onSearch} /> */}
        </Form.Item>
        <Form.Item>
          <RadioGroup onChange={onTabChange2}>
            <RadioButton value="#form">
              {/* <Link to="#form" style={{ textDecoration: 'none' }}> */}
              <span>
                <Icon type="form" />
                &nbsp;Client Info
              </span>
              {/* </Link> */}
            </RadioButton>
            <RadioButton value="#calendar">
              {/* <Link to="#calendar" style={{ textDecoration: 'none' }}> */}
              <span>
                <Icon type="calendar" />
                &nbsp;Calendar
              </span>
              {/* </Link> */}
            </RadioButton>
            <RadioButton value="#listing">
              {/* <Link to="#calendar" style={{ textDecoration: 'none' }}> */}
              <span>
                <Icon type="bars" />
                &nbsp;Listing
              </span>
              {/* </Link> */}
            </RadioButton>
            {/* <RadioButton value="dashboard"><span><Icon type="dashboard" />&nbsp;Stats</span></RadioButton>
            <RadioButton value="form"><span><Icon type="form" />&nbsp;Client Info</span></RadioButton>
            <RadioButton value="calendar"><span><Icon type="calendar" />&nbsp;Calendar</span></RadioButton> */}
          </RadioGroup>
        </Form.Item>
      </Form>
      {/* {(hash === '#dashboard' || !hash) && <ClientDashboard />} */}
      {(hash === '#form' || !hash) && <ClientForm getFieldDecorator={getFieldDecorator} {...props} />}
      {hash === '#calendar' && <ClientCalendar date={props.date} calendarType={props.calendarType} onCalendarTypeChange={props.onCalendarTypeChange} calendar={props.calendar} dateRender={dateRender} onDateChange={onDateChange} onMonthNextClick={onMonthNextClick} onMonthPrevClick={onMonthPrevClick} onActionClick={onActionClick} />}
      {hash === '#listing' && <ClientScheduleTable dates={props.calendar} date={props.date} calendarType={props.calendarType} onCalendarTypeChange={props.onCalendarTypeChange} calendar={props.calendar} dateRender={dateRender} onDateChange={onDateChange} onMonthNextClick={onMonthNextClick} onMonthPrevClick={onMonthPrevClick} onActionClick={onActionClick} />}
    </>
  );
};


export default Form.create({
  name: 'ClientForm',
  mapPropsToFields(props) {
    return {
      id: Form.createFormField(props.formState.id),
      firstName: Form.createFormField(props.formState.firstName),
      lastName: Form.createFormField(props.formState.lastName),
      birthDate: Form.createFormField(props.formState.birthDate),
      phn: Form.createFormField(props.formState.phn),

      phone: Form.createFormField(props.formState.phone),
      email: Form.createFormField(props.formState.email),
      picture: Form.createFormField(props.formState.picture),
      streetAddressLine1: Form.createFormField(props.formState.streetAddressLine1),
      streetAddressLine2: Form.createFormField(props.formState.streetAddressLine2),
      city: Form.createFormField(props.formState.city),
      province: Form.createFormField(props.formState.province),
      postalCode: Form.createFormField(props.formState.postalCode),

      companyName: Form.createFormField(props.formState.companyName),
      companySupervisor: Form.createFormField(props.formState.companySupervisor),
      companySupervisorPhone: Form.createFormField(props.formState.companySupervisorPhone),
      companySupervisorEmail: Form.createFormField(props.formState.companySupervisorEmail),
      contractStartDate: Form.createFormField(props.formState.contractStartDate),
      contractEndDate: Form.createFormField(props.formState.contractEndDate),
      contractNumber: Form.createFormField(props.formState.contractNumber),
      defaultCheckinTimeStart: Form.createFormField(props.formState.defaultCheckinTimeStart),
      defaultCheckinTimeEnd: Form.createFormField(props.formState.defaultCheckinTimeEnd),

      drugOfCompulsion: Form.createFormField(props.formState.drugOfCompulsion),
      treatmentCentre: Form.createFormField(props.formState.treatmentCentre),
      treatmentCentreStartDate: Form.createFormField(props.formState.treatmentCentreStartDate),
      treatmentCentreEndDate: Form.createFormField(props.formState.treatmentCentreEndDate),
      imePerformed: Form.createFormField(props.formState.imePerformed),
      imePerformedBy: Form.createFormField(props.formState.imePerformedBy),
      abstinentDate: Form.createFormField(props.formState.abstinentDate),
      abstinentDays: Form.createFormField(props.formState.abstinentDays),
      additionalServices: Form.createFormField(props.formState.additionalServices),
      notes: Form.createFormField(props.formState.notes),
      archived: Form.createFormField(props.formState.archived),
      statusActive: Form.createFormField(props.formState.statusActive),
    };
  },
  onFieldsChange(props, formState) {
    props.onFieldChange(formState);
  },
})(Client);
