import React from 'react';
import { Table, Menu, Dropdown, Icon, Alert, Form, Button, DatePicker } from 'antd';
import moment from 'moment';

// import DailyFilter from '../Daily/DailyFilter';
import CheckinCell from '../Cells/CheckinCell';
import SampleCell from '../Cells/SampleCell';
import ScreeningCell from '../Cells/ScreeningCell';
import ForensicCell from '../Cells/ForensicCell';
import BreachCell from '../Cells/BreachCell';

const { MonthPicker } = DatePicker;
const ClientScheduleTable = (props) => {
  const {
    dates,
    count,
    page,
    pageSize,
    error,
    loading,

    onActionClick,
    onPageChange,
    onPageSizeChange,

    onMonthPrevClick,
    onMonthNextClick,
    onDateChange,
    date,
  } = props;

  const columns = [
    {
      title: 'Date',
      width: '100px',
      dataIndex: 'calendarDate',
    },
    {
      title: 'Checkin',
      width: '86px',
      align: 'center',
      render: (_text, record) => <CheckinCell {...record} />,
    },
    {
      title: 'Sample',
      width: '86px',
      align: 'center',
      render: (_text, record) => <SampleCell {...record} />,
    },
    {
      title: 'Screening',
      width: '86px',
      align: 'center',
      render: (_text, record) => <ScreeningCell {...record} />,
    },
    {
      title: 'Forensic',
      width: '86px',
      align: 'center',
      render: (_text, record) => <ForensicCell {...record} />,
    },
    {
      title: 'Breach',
      width: '86px',
      align: 'center',
      render: (_text, record) => <BreachCell {...record} />,
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
    },
    {
      title: 'Actions',
      width: '100px',
      render: (_text, record) => {
        const recDay = moment(record.calendarDate);
        const today = moment(moment().format('YYYY-MM-DD'), 'YYYY-MM-DD', true);
        const menu = recDay.isAfter(today)
          ? (
            <Menu onClick={({ key }) => onActionClick(key, record)}>
              <Menu.Item key="schedule">Schedule</Menu.Item>
              <Menu.Item key="batchplan">Batch Schedule</Menu.Item>
            </Menu>
          ) : (
            <Menu onClick={({ key }) => onActionClick(key, record)}>
              <Menu.Item key="schedule">Schedule</Menu.Item>
              <Menu.Item key="checkin">Checkin</Menu.Item>
              <Menu.Item key="screening">Screening</Menu.Item>
              <Menu.Item key="forensic">Forensic</Menu.Item>
              <Menu.Item key="breach">Breach</Menu.Item>
            </Menu>
          );

        return (
          <Dropdown overlay={menu} trigger={['click']}>
            <a className="ant-dropdown-link" href="#">
              Action <Icon type="down" style={{ fontSize: '12px' }} />
            </a>
          </Dropdown>
        );
      },
    },
  ];

  return (
    <>
      <Form layout="inline">
        <Form.Item>
          <Button onClick={onMonthPrevClick} icon="left" />
          <MonthPicker style={{ width: 100 }} value={date} allowClear={false} onChange={onDateChange} />
          <Button onClick={onMonthNextClick} icon="right" />
        </Form.Item>
      </Form>

      <Table
        size="small"
        className="client-schedule-table"
        columns={columns}
        dataSource={dates}
        rowKey="id"
        loading={loading}
        pagination={false}
        scroll={{ y: 600 }}
      // pagination={{
      //   total: count,
      //   showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
      //   pageSize,
      //   current: page,
      //   onChange: onPageChange,
      //   showSizeChanger: true,
      //   onShowSizeChange: onPageSizeChange,
      // }}
      />
    </>
  );
};

export default ClientScheduleTable;
