import { withRouter } from 'react-router-dom';
import React from 'react';
import { Button, Icon } from 'antd';

const BackButton = ({ history, style }) => (
  <Button style={style} onClick={() => history.goBack()}>
    <Icon type="left" />
    {' '}
    Back
  </Button>
);

export default withRouter(BackButton);
