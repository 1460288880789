import { connect } from 'react-redux';

import BatchPlanModal from './BatchPlanModal';

import {
  batchPlanModalClose,
  batchPlanModalSave,
  batchPlanModalSetField,
} from '../../redux/actions/batchPlanActions';

const mapStateToProps = state => ({
  ...state.batchPlanModal,
});

const mapDispatchToProps = dispatch => ({
  onCancel() {
    dispatch(batchPlanModalClose());
  },
  onSave(form) {
    dispatch(batchPlanModalSave(form));
  },
  onFieldChange(formState) {
    dispatch(batchPlanModalSetField(formState));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BatchPlanModal);
