import React from 'react';
import { Table, Alert } from 'antd';
import { Link } from 'react-router-dom';

import ClientListingFilter from './ClientListingFilter';

const ClientListing = (props) => {
  const {
    clients,
    count,
    page,
    pageSize,

    loading,
    error,
    onPageChange,
    onPageSizeChange,
  } = props;

  const columns = [{
    title: 'Name',
    dataIndex: 'name',
    render: (_name, row) => <Link to={`/clients/${row.id}`}>{row.name}</Link>,
  }, {
    title: 'City',
    dataIndex: 'city',
  }, {
    title: 'Company',
    dataIndex: 'companyName',
  }, {
  //   title: 'Abstinent Days',
  //   dataIndex: 'abstinentDays',
  // }, {
    title: 'Contract Start',
    dataIndex: 'contractStartDate',
  }, {
    title: 'Contract End',
    dataIndex: 'contractEndDate',
  }, {
  //   title: 'Additional Services',
  //   dataIndex: 'additionalServices',
  // }, {
    title: 'Status',
    dataIndex: 'archived',
    render: archived => (archived ? 'Archived' : 'Active'),
  }];

  return (
    <>
      {error && <Alert type="error" message={error.message} description={error.description} banner /> }
      <ClientListingFilter {...props} style={{ padding: 8, backgroundColor: '#f0f2f5', marginBottom: 16 }} />
      <Table
        className="simple-border"
        columns={columns}
        dataSource={clients}
        rowKey="id"
        loading={loading}
        // scroll={{ y: 100 }}
        pagination={{
          total: count,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
          pageSize,
          current: page,
          onChange: onPageChange,
          showSizeChanger: true,
          onShowSizeChange: onPageSizeChange,
        }}
      />
    </>
  );
};

export default ClientListing;
