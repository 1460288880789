import moment from 'moment';

import { postPlan } from '../../../Common/api';

import {
  modalOpen,
  modalClose,
  modalSetField,
  modalSavingStart,
  modalSavingSuccess,
  modalSavingFailure,
} from './modal_actions';

export const PLANNING_MODAL_OPEN = 'PLANNING_MODAL_OPEN';
export const PLANNING_MODAL_CLOSE = 'PLANNING_MODAL_CLOSE';
export const PLANNING_MODAL_SET_FIELD = 'PLANNING_MODAL_SET_FIELD';
export const PLANNING_MODAL_SAVING_START = 'PLANNING_MODAL_SAVING_START';
export const PLANNING_MODAL_SAVING_SUCCESS = 'PLANNING_MODAL_SAVING_SUCCESS';
export const PLANNING_MODAL_SAVING_FAILURE = 'PLANNING_MODAL_SAVING_FAILURE';

export function planningModalOpen(data, onSave) {
  const formState = {
    clientId: { value: data.client.id },
    clientName: { value: data.client.name },
    calendarDate: { value: moment(data.calendarDate, 'YYYY-MM-DD') },
    checkinRequired: { value: data.checkinRequired || false },
    checkinNotRequiredReason: { value: data.checkinNotRequiredReason },

    checkinOverrideTime: { value: data.checkinOverrideTime || false },
    checkinStartTime: { value: data.checkinStartTime ? moment(data.checkinStartTime, 'HH:mm') : null },
    checkinEndTime: { value: data.checkinEndTime ? moment(data.checkinEndTime, 'HH:mm') : null },
    sampleRequired: { value: data.sampleRequired || false },
    sampleStartTime: { value: data.sampleStartTime ? moment(data.sampleStartTime, 'HH:mm') : null },
    sampleEndTime: { value: data.sampleEndTime ? moment(data.sampleEndTime, 'HH:mm') : null },
    sampleInstructions: { value: data.sampleInstructions },
  };

  return modalOpen(PLANNING_MODAL_OPEN, formState, onSave);
}

export function planningModalClose() {
  return modalClose(PLANNING_MODAL_CLOSE);
}

export function planningModalSetField(formState) {
  return modalSetField(PLANNING_MODAL_SET_FIELD, formState);
}

export function planningModalSavingStart() {
  return modalSavingStart(PLANNING_MODAL_SAVING_START);
}

export function planningModalSavingSuccess() {
  return modalSavingSuccess(PLANNING_MODAL_SAVING_SUCCESS);
}

export function planningModalSavingFailure(error) {
  return modalSavingFailure(PLANNING_MODAL_SAVING_FAILURE, 'Unable to save planning', error);
}

export function planningModalSave(form) {
  return (dispatch, getState) => {
    const { planningModal } = getState();
    const { onSave } = planningModal;

    form.validateFields({ force: true }, (err, values) => {
      // If there are errors then cancel the save.
      // Any errors will be displayed per field on the form.
      if (err) {
        return;
      }

      const calendarDate = values.calendarDate.format('YYYY-MM-DD');

      const {
        clientId,
        checkinRequired,
        checkinOverrideTime,
        sampleRequired,
      } = values;

      const checkinNotRequiredReason = (checkinRequired ? null : values.checkinNotRequiredReason);
      const checkinStartTime = (checkinOverrideTime ? values.checkinStartTime.format('HH:mm') : null);
      const checkinEndTime = (checkinOverrideTime ? values.checkinEndTime.format('HH:mm') : null);
      const sampleStartTime = (sampleRequired ? values.sampleStartTime.format('HH:mm') : null);
      const sampleEndTime = (sampleRequired ? values.sampleEndTime.format('HH:mm') : null);
      const sampleInstructions = (sampleRequired ? values.sampleInstructions : null);

      const body = {
        checkinRequired,
        checkinNotRequiredReason,
        checkinOverrideTime,
        checkinStartTime,
        checkinEndTime,
        sampleRequired,
        sampleStartTime,
        sampleEndTime,
        sampleInstructions,
      };

      dispatch(planningModalSavingStart());

      postPlan({ clientId, calendarDate }, body)
        .then((obj) => {
          dispatch(planningModalSavingSuccess(obj));
          dispatch(planningModalClose());
          onSave();
        })
        .catch((apiErr) => {
          dispatch(planningModalSavingFailure(apiErr));
        });
    });
  };
}
