import React from 'react';
import moment from 'moment';
import { Form, Input, DatePicker, Col, Row, TimePicker, Checkbox, Button, InputNumber, Statistic, Progress, Card, Switch, Upload, Icon } from 'antd';
import ClientStat from './ClientStat';
import NoClientPicture from '../../Common/noClientPicture.png';

const gridStyle = { width: `${100.00 / 7}%`, textAlign: 'center', paddingTop: 8, paddingBottom: 8 };
// console.log('GRID STYLE', gridStyle.width);
const ClientForm = ({ getFieldDecorator,
  onCancelClick,
  onSaveClick,
  isSaving,
  canDiscard,
  canSave,
  form,
  data,
  formState }) => {
  const { stats, abstinentDays, contractTotalDays, contractUsedDays } = data;

  // console.log('formState', formState);
  if (!formState.treatmentCentre) {
    return null;
  }
  const { treatmentCentre: { value: treatmentCentre } } = formState;

  const handleUpload = (event) => {
    // console.log('handleUpload event');
    // console.log(event);
    if (event.file && event.file.response) {
      // console.log('return event.file.response');
      return event.file.response;
    }
    // console.log('event.file.response not found');
    return null;
  };

  return (
    <>
      {stats
        && (
          <Card style={{ marginBottom: 16 }}>
            <Card.Grid style={gridStyle}>
              <Statistic title="Checkins" value={stats.checkin.missed + stats.checkin.late} suffix={`/${stats.checkin.complete + stats.checkin.late + stats.checkin.missed + stats.checkin.pending}`} />
              <Progress strokeColor="red" successPercent={10} percent={60} showInfo={false} />
            </Card.Grid>
            <Card.Grid style={gridStyle}>
              <Statistic title="Screening" value={stats.screening.nonNegative} suffix={`/${stats.screening.nonNegative + stats.screening.negative + stats.screening.missed + stats.screening.pending}`} />
              <Progress percent={60} successPercent={30} showInfo={false} />
            </Card.Grid>
            <Card.Grid style={gridStyle}>
              <Statistic title="Forensic" value={stats.forensic.positive} suffix={`/${stats.forensic.negative + stats.forensic.positive + stats.forensic.pending}`} />
              <Progress percent={60} successPercent={30} showInfo={false} />
            </Card.Grid>
            <Card.Grid style={gridStyle}>
              <Statistic title="Abstinent Days" value={abstinentDays} />
              &nbsp;
            </Card.Grid>
            <Card.Grid style={gridStyle}>
              <Statistic title="Contract Days" value={contractUsedDays} suffix={`/${contractTotalDays}`} />
              &nbsp;
            </Card.Grid>
            <Card.Grid style={gridStyle}>
              <Statistic title="Yellow Breach" value={stats.breach.yellow} />
              &nbsp;
            </Card.Grid>
            <Card.Grid style={gridStyle}>
              <Statistic title="Red Breaches" value={stats.breach.red} />
              &nbsp;
            </Card.Grid>
          </Card>
        )
      }

      <Form layout="vertical" className="smallform">

        <Row gutter={16}>
          <Col span={4}>
            <Form.Item label="">
              {getFieldDecorator('picture', {
                // valuePropName: 'file',
                getValueFromEvent: handleUpload,
              })(
                <Upload
                  multiple={false}
                  showUploadList={false}
                  customRequest={({
                    action,
                    data,
                    file,
                    filename,
                    headers,
                    onError,
                    onProgress,
                    onSuccess,
                    withCredentials,
                  }) => {
                    const reader = new global.FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => {
                      onSuccess(reader.result);
                    };
                    reader.onerror = (error) => {
                      console.log('File Read Error: ', error);
                      onError(error);
                    };
                  }}
                >
                  <Card
                    style={{ width: '100%', height: '100%' }}
                    cover={(<img alt="profilePicture" src={formState.picture.value || NoClientPicture} />)}
                    actions={(<div>Click to Upload/Change</div>)}
                  >
                    <Button>
                      <Icon type="upload" />
                      Upload
                    </Button>
                  </Card>
                </Upload>,
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <div className="ant-card-bordered" style={{ padding: 12 }}>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label="Client Id" style={{ display: 'none' }}>
                    {getFieldDecorator('id', {})(
                      <Input />,
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="First Name">
                    {getFieldDecorator('firstName', {
                      rules: [{
                        required: true,
                        message: 'First Name is required.',
                      }],
                    })(
                      <Input />,
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Last Name">
                    {getFieldDecorator('lastName', {
                      rules: [{
                        required: true,
                        message: 'Last Name is required.',
                      }],
                    })(
                      <Input />,
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Birth Date">
                    {getFieldDecorator('birthDate', {
                      rules: [{
                        required: true,
                        message: 'Birth Date is required.',
                      },
                      {
                        validator: (rule, birthMoment, callback) => {
                          // console.log('Rule', rule);
                          if (birthMoment && birthMoment.isValid()
                            && birthMoment.isAfter(moment().add(-5, 'years'))) {
                            callback('Birth Date is too recent.');
                          } else {
                            callback();
                          }
                        },
                      }],
                    })(
                      <DatePicker allowClear={false} />,
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="PHN">
                    {getFieldDecorator('phn', {
                      rules: [{
                        required: true,
                        len: 10,
                        message: 'PHN is required.',
                      }],
                    })(
                      <Input />,
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Phone">
                    {getFieldDecorator('phone', {
                      rules: [{
                        required: true,
                        message: 'Phone is required.',
                      }],
                    })(
                      <Input />,
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Email">
                    {getFieldDecorator('email', {
                      rules: [{
                        required: true,
                        type: 'email',
                        message: 'Email is required.',
                      }],
                    })(
                      <Input disabled={formState.id.value > 0} />,
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Col>
          <Col span={6}>
            <div className="ant-card-bordered" style={{ padding: 12 }}>
              <Row gutter={16}>
                <Col span={24}>

                  <Form.Item label="Street Address Line 1">
                    {getFieldDecorator('streetAddressLine1', {
                      rules: [{
                        required: true,
                        message: 'Street Address Line 1 is required.',
                      }],
                    })(
                      <Input />,
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="Street Address Line 2">
                    {getFieldDecorator('streetAddressLine2', {
                    })(
                      <Input />,
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>

                  <Form.Item label="City">
                    {getFieldDecorator('city', {
                      rules: [{
                        required: true,
                        message: 'City is required.',
                      }],
                    })(
                      <Input />,
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>

                  <Form.Item label="Province">
                    {getFieldDecorator('province', {
                      rules: [{
                        required: true,
                        message: 'Province is required.',
                      }],
                    })(
                      <Input />,
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>

                  <Form.Item label="Postal Code">
                    {getFieldDecorator('postalCode', {
                      rules: [{
                        required: true,
                        message: 'Postal Code is required.',
                      }],
                    })(
                      <Input />,
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Col>
          <Col span={6}>
            <div className="ant-card-bordered" style={{ padding: 12 }}>
              <Row gutter={16}>
                <Col span={24}>

                  <Form.Item label="Company Name">
                    {getFieldDecorator('companyName', {
                      rules: [{
                        required: true,
                        message: 'Company Name is required.',
                      }],
                    })(
                      <Input />,
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>

                  <Form.Item label="HR Contact">
                    {getFieldDecorator('companySupervisor', {
                      rules: [{
                        required: true,
                        message: 'HR Contact is required.',
                      }],
                    })(
                      <Input />,
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>

                  <Form.Item label="HR Contact Phone">
                    {getFieldDecorator('companySupervisorPhone', {
                      rules: [{
                        required: true,
                        message: 'HR Contact Phone is required.',
                      }],
                    })(
                      <Input />,
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>

                  <Form.Item label="HR Contact Email">
                    {getFieldDecorator('companySupervisorEmail', {
                      rules: [{
                        required: true,
                        type: 'email',
                        message: 'HR Contact Email is required.',
                      }],
                    })(
                      <Input />,
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row style={{ marginTop: '16px' }} gutter={16}>
          <Col span={8}>
            <div className="ant-card-bordered" style={{ padding: 12 }}>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="Contract Start">
                    {getFieldDecorator('contractStartDate', {
                      rules: [{
                        required: true,
                        message: 'Contract Start is required.',
                      }],
                    })(
                      <DatePicker allowClear={false} disabled={formState.id.value > 0} />,
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Contract End">
                    {getFieldDecorator('contractEndDate', {
                      rules: [{
                        required: true,
                        message: 'Contract End is required.',
                      }],
                    })(
                      <DatePicker allowClear={false} disabled={formState.id.value > 0} />,
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Contract Number">
                    {getFieldDecorator('contractNumber', {
                      rules: [{
                        required: true,
                        message: 'Contract Number is required.',
                      }],
                    })(
                      <InputNumber min={1} disabled={formState.id.value > 0} />,
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Status">
                    {getFieldDecorator('statusActive', {
                      // validateTrigger: ['onChange', 'onBlur'],
                      // initialValue: formState.archived.value ? '"true"' : '"false"',
                      valuePropName: 'checked',
                    })(
                      <Switch checkedChildren="Active" unCheckedChildren="Archived" />,
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="Daily Checkin Earliest">
                    {getFieldDecorator('defaultCheckinTimeStart', {
                      rules: [{
                        required: true,
                        message: 'Daily Checkin Earliest is required.',
                      }],
                    })(
                      <TimePicker format="hh A" use12Hours allowClear={false} />,
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Daily Checkin Latest">
                    {getFieldDecorator('defaultCheckinTimeEnd', {
                      rules: [{
                        required: true,
                        message: 'Daily Checkin Latest is required.',
                      }],
                    })(
                      <TimePicker format="hh A" use12Hours allowClear={false} />,
                    )}
                  </Form.Item>
                </Col>


              </Row>
            </div>
          </Col>
          <Col span={8}>
            <div className="ant-card-bordered" style={{ padding: 12 }}>
              <Row gutter={16}>

                <Col span={24}>
                  <Form.Item label="Drug(s) of Compulsion">
                    {getFieldDecorator('drugOfCompulsion', {
                      rules: [{
                        required: true,
                        message: 'Drug of Compulsion is required.',
                      }],
                    })(
                      <Input />,
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Abstinent Date">
                    {getFieldDecorator('abstinentDate', {
                      rules: [{
                        required: true,
                        message: 'Abstinent Date is required.',
                      }],
                    })(
                      <DatePicker />,
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="Additional Services">
                    {getFieldDecorator('additionalServices', {
                    })(
                      <Input />,
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Col>
          <Col span={8}>
            <div className="ant-card-bordered" style={{ padding: 12 }}>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label="Treatment Centre">
                    {getFieldDecorator('treatmentCentre', {
                    })(
                      <Input />,
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Treatment Centre Start">
                    {getFieldDecorator('treatmentCentreStartDate', {
                      rules: [{
                        required: treatmentCentre,
                        message: 'Treatment Centre Start is required.',
                      }],
                    })(
                      <DatePicker allowClear={false} />,
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Treatment Centre End">
                    {getFieldDecorator('treatmentCentreEndDate', {
                      rules: [{
                        required: treatmentCentre,
                        message: 'Treatment Centre End is required.',
                      }],
                    })(
                      <DatePicker allowClear={false} />,
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="IME Performed">
                    {getFieldDecorator('imePerformed', {
                      valuePropName: 'checked',
                    })(
                      <Checkbox />,
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="IME Performed By">
                    {getFieldDecorator('imePerformedBy', {
                      rules: [{
                        required: formState.imePerformed.value,
                        message: 'required if IME Performed',
                      }],
                    })(
                      <Input disabled={!formState.imePerformed.value} />,
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row style={{ marginTop: '16px' }}>
          <Col span={24} style={{ textAlign: 'right' }}>
            <Button
              icon="rollback"
              onClick={() => {
                if (formState.id.value) onCancelClick(formState.id.value);
              }}
              disabled={!canDiscard}
            >
              Cancel
            </Button>
            <Button
              style={{ marginLeft: 8 }}
              type="primary"
              icon="save"
              loading={isSaving}
              onClick={() => onSaveClick(form)}
              disabled={!canSave}
            >
              Save
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};
export default ClientForm;
