import { connect } from 'react-redux';

import PlanningModal from './PlanningModal';

import {
  planningModalClose,
  planningModalSave,
  planningModalSetField,
} from '../../redux/actions/planningActions';

const mapStateToProps = state => ({
  ...state.planningModal,
});

const mapDispatchToProps = dispatch => ({
  onCancel() {
    dispatch(planningModalClose());
  },
  onSave(form) {
    dispatch(planningModalSave(form));
  },
  onFieldChange(formState) {
    dispatch(planningModalSetField(formState));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PlanningModal);
