import React, { Component } from 'react';
import { withRouter, Switch, Redirect, Route } from 'react-router-dom';

import 'antd/dist/antd.css';
import './index.css';

import { getClientCheckin, clientCheckin, myClient } from '../Common/api';
import AppView from './AppView';
import api from '../Common/clientApi';
import history from '../Common/history';
import ContactPage from './Contact/ContactPage';
import HomePage from './Home/HomePage';
import MessagesController from './Message/MessagesController';
import ProfilePage from './Profile/ProfilePage';
import LogoutPage from './Logout/LogoutPage';


class ClientController extends Component {
  constructor(props) {
    super(props);

    this.api = api;
    this.history = history;

    this.state = {
      menuCollapsed: true,
      page: 'checkin',
      unreadMessageCount: 0,

      cleanDate: null,
      cleanDays: null,
      cleanDaysProcessing: false,
      cleanDaysError: null,

      phone: process.env.REACT_APP_CONTACT_PHONE,
      addressLine1: process.env.REACT_APP_CONTACT_ADDRESS_LINE_1,
      addressLine2: process.env.REACT_APP_CONTACT_ADDRESS_LINE_2,
      addressLine3: process.env.REACT_APP_CONTACT_ADDRESS_LINE_3,
      mapUrl: process.env.REACT_APP_CONTACT_MAP_URL,
    };

    this.onCheckinClick = this.onCheckinClick.bind(this);
    this.onGetMessages = this.onGetMessages.bind(this);
    this.onGetMoreMessages = this.onGetMoreMessages.bind(this);
    this.onSendMessage = this.onSendMessage.bind(this);
  }

  async componentWillMount() {
    await Promise.all([
      this.loadCleanDays(),
      this.loadUnreadMessageCount(),
      this.loadCheckin(),
    ]);
  }

  // componentDidMount() {
  //   const self = this;

  //   // Check for unread messages every 60 seconds.
  //   window.setInterval(self.loadUnreadMessageCount, 60000);

  //   // Check for expired sessions every 60.
  //   window.setInterval(self.checkAuthenticated, 60000);
  // }

  /**
   * Called when a user want to perform their daily check-in.
   */
  async onCheckinClick() {
    this.setState({
      checkinProcessing: true,
      checkinError: false,
      checkinRecord: false,
    }, async () => {
      try {
        const checkinRecord = await clientCheckin();

        this.setState({
          checkinProcessing: false,
          checkinError: null,
          checkinRecord,
        });

      } catch (err) {
        console.error('An error occured while trying to check-in (onCheckin).', err);
        console.log('INSIDE CATCH', err, err.body);
        this.setState({
          checkinProcessing: false,
          checkinError: err.body,
          checkinRecord: null,
        });
      }
    })
  }

  onSendMessage(message, cb) {
    this.api.sendMessage(message, cb);
  }

  onGetMoreMessages(moreKey, cb) {
    this.api.getMoreMessages(moreKey, cb);
  }

  onGetMessages(cb) {
    this.api.getMessages((err, res) => {
      if (err) {
        console.error('An error occured while trying to get messages (onGetMessages).', err);
      }
      this.setState({
        unreadMessageCount: 0,
      });
      cb(err, res);
    });
  }

  loadUnreadMessageCount() {
    api.getUnreadMessageCount((err, res) => {
      if (err) {
        console.error('An error occured while trying to get unread message count (loadUnreadMessageCount).', err);
      }
      this.setState({
        // TODO - What do we display to the user if the unread message count failed to load??
        unreadMessageCount: res,
      });
    });
  }

  /**
   * This function loads the clean day information for the client.
   */
  async loadCleanDays() {
    this.setState({
      cleanDaysProcessing: true,
    }, async () => {
      try {
        const clientRecord = await myClient();

        this.setState({
          cleanDays: clientRecord.abstinentDays,
          cleanDate: clientRecord.abstinentDate,
          cleanDaysError: null,
          cleanDaysProcessing: false,
        });
      } catch (err) {
        console.error('An error occured while trying to get check-in data (loadCleanDays).', err);

        this.setState({
          cleanDays: null,
          cleanDate: null,
          cleanDaysError: err,
          cleanDaysProcessing: false,
        });
      }
    });
  }

  /**
   * This function is used to load existing check-in data.
   * For instance, if a user has not checked in for the day, this would return nothing.
   * If the user has already checked in, and then logs in again, it would return the existing
   * check-in record.
   *
   * This function does NOT actually check-in the user.
   */
  async loadCheckin() {
    try {
      const checkinRecord = await getClientCheckin();

      this.setState({
        checkinProcessing: false,
        checkinError: null,
        checkinRecord,
      });
    } catch (err) {
      console.error('An error occured while trying to get check-in data (loadCheckin).', err);

      this.setState({
        checkinProcessing: false,
        checkinError: err,
        checkinRecord: null,
      });
    }
  }

  render() {
    const {
      checkinError,
      checkinRecord,
      checkinProcessing,
      cleanDays,
      cleanDaysError,
      cleanDaysProcessing,
      cleanDate,
      phone,
      addressLine1,
      addressLine2,
      addressLine3,
      mapUrl,
    } = this.state;

    const {
      changePasswordError,
      changePasswordProcessing,
      changePasswordSuccess,
      onChangePassword,

      logoutProcessing,
      onLogout,
    } = this.props;

    return (
      <AppView>
        <Switch>
          <Redirect exact from="/" to="/home" />
          <Route
            path="/home"
            render={() => (
              <HomePage
                checkinRecord={checkinRecord}
                checkinLoading={checkinProcessing}
                checkinError={checkinError}
                onCheckinClick={this.onCheckinClick}
                cleanDays={cleanDays}
                cleanDate={cleanDate}
                cleanDaysProcessing={cleanDaysProcessing}
                cleanDaysError={cleanDaysError}

              />
            )}
          />
          <Route
            path="/messages"
            render={() => (
              <MessagesController
                onGetMessages={this.onGetMessages}
                onGetMoreMessages={this.onGetMoreMessages}
                onSendMessage={this.onSendMessage}
              />
            )}
          />
          <Route
            path="/contact"
            render={() => (
              <ContactPage
                phone={phone}
                addressLine1={addressLine1}
                addressLine2={addressLine2}
                addressLine3={addressLine3}
                mapUrl={mapUrl}
              />
            )}
          />
          <Route
            path="/profile"
            render={() => (
              <ProfilePage
                changePasswordProcessing={changePasswordProcessing}
                changePasswordError={changePasswordError}
                changePasswordSuccess={changePasswordSuccess}
                onChangePasswordClick={onChangePassword}
              />
            )}
          />
          <Route
            path="/logout"
            render={() => (
              <LogoutPage
                logoutProcessing={logoutProcessing}
                onLogout={onLogout}
              />
            )}
          />
        </Switch>
      </AppView>
    );
  }
}

export default withRouter(ClientController);
