import { connect } from 'react-redux';

import CheckinModal from './CheckinModal';

import {
  checkinModalClose,
  checkinModalSave,
  checkinModalSetField,
} from '../../redux/actions/checkinActions';

const mapStateToProps = state => ({
  ...state.checkinModal,
});

const mapDispatchToProps = dispatch => ({
  onCancel() {
    dispatch(checkinModalClose());
  },
  onSave(form) {
    dispatch(checkinModalSave(form));
  },
  onFieldChange(formState) {
    dispatch(checkinModalSetField(formState));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CheckinModal);
