import React from 'react';
import { Table, Menu, Dropdown, Icon, Alert } from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment';

import DailyFilter from './DailyFilter';
import CheckinCell from '../Cells/CheckinCell';
import SampleCell from '../Cells/SampleCell';
import ScreeningCell from '../Cells/ScreeningCell';
import ForensicCell from '../Cells/ForensicCell';
import BreachCell from '../Cells/BreachCell';

const Daily = (props) => {
  const {
    dates,
    count,
    page,
    pageSize,
    error,
    loading,

    onActionClick,
    onPageChange,
    onPageSizeChange,
  } = props;

  const columns = [
    {
      title: 'Client',
      dataIndex: 'client.name',
      render: (_name, row) => <Link to={`/clients/${row.client.id}`}>{row.client.name}</Link>,
      width: '200px',
    },
    {
      title: 'Checkin',
      render: (_text, record) => <CheckinCell {...record} />,
      width: '86px',
      align: 'center',
    },
    {
      title: 'Sample',
      render: (_text, record) => <SampleCell {...record} />,
      width: '86px',
      align: 'center',
    },
    {
      title: 'Screening',
      render: (_text, record) => <ScreeningCell {...record} />,
      width: '86px',
      align: 'center',
    },
    {
      title: 'Forensic',
      render: (_text, record) => <ForensicCell {...record} />,
      width: '86px',
      align: 'center',
    },
    {
      title: 'Breach',
      render: (_text, record) => <BreachCell {...record} />,
      width: '86px',
      align: 'center',
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
    },
    {
      title: 'Actions',
      width: '100px',
      align: 'center',
      render: (_text, record) => {
        const recDay = moment(record.calendarDate);
        const today = moment(moment().format('YYYY-MM-DD'), 'YYYY-MM-DD', true);
        const menu = recDay.isAfter(today)
          ? (
            <Menu onClick={({ key }) => onActionClick(key, record)}>
              <Menu.Item key="planning">Plan</Menu.Item>
              <Menu.Item key="batchplan">Batch Plan</Menu.Item>
            </Menu>
          ) : (
            <Menu onClick={({ key }) => onActionClick(key, record)}>
              <Menu.Item key="planning">Plan</Menu.Item>
              <Menu.Item key="checkin">Checkin</Menu.Item>
              <Menu.Item key="screening">Screening</Menu.Item>
              <Menu.Item key="forensic">Forensic</Menu.Item>
              <Menu.Item key="breach">Breach</Menu.Item>
            </Menu>
          );

        return (
          <Dropdown overlay={menu} trigger={['click']}>
            <a className="ant-dropdown-link" href="#">
              Action <Icon type="down" style={{ fontSize: '12px' }} />
            </a>
          </Dropdown>
        );
      },
    },
  ];

  return (
    <>
      {error && <Alert type="error" message={error.message} description={error.description} banner />}
      <DailyFilter {...props} style={{ padding: 8, backgroundColor: '#f0f2f5', marginBottom: 16 }} />
      <Table
        className="daily-table simple-border"
        columns={columns}
        dataSource={dates}
        rowKey="id"
        loading={loading}
        pagination={{
          total: count,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
          pageSize,
          current: page,
          onChange: onPageChange,
          showSizeChanger: true,
          onShowSizeChange: onPageSizeChange,
        }}
      />
    </>
  );
};

export default Daily;
