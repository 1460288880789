import React from 'react';
import { Alert, Card, Spin } from 'antd';
import moment from 'moment';

const CleanCard = ({
}) => {
  const phone = process.env.REACT_APP_CONTACT_PHONE;
  // const description = <p>Please remember that you must contact the office cell phone at <a href={`tel:+1${phone}`}>{phone}</a> to leave information about holidays/accountability group/doctors appointments, medication changes, etc as per your contract.</p>;
  const description = <p>Please remember that you must contact the office cell phone at <a href={`tel:+1${phone}`}>{phone}</a> as per your contract. For example: <ul><li>Holidays</li><li>Accountability Groups</li><li>Medication Changes</li><li>Etc</li></ul></p>;
  return (
    <Alert type="warning" description={description} />
  );
};

export default CleanCard;
