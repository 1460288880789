import moment from 'moment';

import { postBreach } from '../../../Common/api';

import {
  modalOpen,
  modalClose,
  modalSetField,
  modalSavingStart,
  modalSavingSuccess,
  modalSavingFailure,
} from './modal_actions';

export const BREACH_MODAL_OPEN = 'BREACH_MODAL_OPEN';
export const BREACH_MODAL_CLOSE = 'BREACH_MODAL_CLOSE';
export const BREACH_MODAL_SET_FIELD = 'BREACH_MODAL_SET_FIELD';
export const BREACH_MODAL_SAVING_START = 'BREACH_MODAL_SAVING_START';
export const BREACH_MODAL_SAVING_SUCCESS = 'BREACH_MODAL_SAVING_SUCCESS';
export const BREACH_MODAL_SAVING_FAILURE = 'BREACH_MODAL_SAVING_FAILURE';

export function breachModalOpen(data, onSave) {
  const formState = {
    clientId: { value: data.client.id },
    clientName: { value: data.client.name },
    calendarDate: { value: moment(data.calendarDate, 'YYYY-MM-DD') },
    breachOccurred: { value: data.breachOccurred },
    breachType: { value: data.breachType },
    breachNote: { value: data.breachNote },
  };

  return modalOpen(BREACH_MODAL_OPEN, formState, onSave);
}

export function breachModalClose() {
  return modalClose(BREACH_MODAL_CLOSE);
}

export function breachModalSetField(formState) {
  return modalSetField(BREACH_MODAL_SET_FIELD, formState);
}

export function breachModalSavingStart() {
  return modalSavingStart(BREACH_MODAL_SAVING_START);
}

export function breachModalSavingSuccess() {
  return modalSavingSuccess(BREACH_MODAL_SAVING_SUCCESS);
}

export function breachModalSavingFailure(error) {
  return modalSavingFailure(BREACH_MODAL_SAVING_FAILURE, 'Unable to save breach', error);
}

export function breachModalSave(form) {
  return (dispatch, getState) => {
    const { breachModal } = getState();
    const { onSave } = breachModal;

    form.validateFields({ force: true }, (err, values) => {
      // If there are errors then cancel the save.
      // Any errors will be displayed per field on the form.
      if (err) {
        return;
      }

      const calendarDate = values.calendarDate.format('YYYY-MM-DD');

      const {
        clientId,
        breachOccurred,
        // breachType,
        // breachNote,
      } = values;

      const breachType = values.breachOccurred ? values.breachType : null;
      const breachNote = values.breachOccurred ? values.breachNote : null;

      dispatch(breachModalSavingStart());

      postBreach({ clientId, calendarDate }, { breachOccurred, breachType, breachNote })
        .then((obj) => {
          dispatch(breachModalSavingSuccess(obj));
          dispatch(breachModalClose());
          onSave();
        })
        .catch((apiErr) => {
          dispatch(breachModalSavingFailure(apiErr));
        });
    });
  };
}
