import React from 'react';
import moment from 'moment';

import { DatePicker, Form, Input, Switch, Button, Row, Col, TimePicker, Divider } from 'antd';

const BatchPlanForm = (props) => {
  const { canSave, canCancel, onSave, onCancel, isProcessing, form } = props;
  const { getFieldDecorator } = form;

  const formItemLayout = null;

  const {
    formState: {
      calendarStartDate: { value: calendarStartDate },
      calendarEndDate: { value: calendarEndDate },
      checkinRequired: { value: checkinRequired },
      checkinOverrideTime: { value: checkinOverrideTime },
      checkinStartTime: { value: checkinStartTime },
      checkinEndTime: { value: checkinEndTime },
      sampleRequired: { value: sampleRequired },
      sampleStartTime: { value: sampleStartTime },
      sampleEndTime: { value: sampleEndTime },
    },
  } = props;

  return (
    <Form layout="horizontal">
      <Form.Item label="Client Id" {...formItemLayout} style={{ display: 'none' }}>
        {getFieldDecorator('clientId', {
        })(
          <Input disabled />,
        )}
      </Form.Item>

      <Row gutter={8}>
        <Col span={8}>
          <Form.Item label="Client" {...formItemLayout}>
            {getFieldDecorator('clientName', {
            })(
              <Input disabled />,
            )}
          </Form.Item>

        </Col>
        <Col span={8}>
          <Form.Item label="Start Date" {...formItemLayout}>
            {getFieldDecorator('calendarStartDate', {
              rules: [{
                required: true,
                message: 'Start Date is required.',
              },
              {
                validator: (rule, startMoment, callback) => {
                  // console.log('Rule', rule);
                  if (!startMoment || !startMoment.isValid()) {
                    callback('Start Date is not valid.');
                  } else if (startMoment.isAfter(moment(calendarEndDate))) {
                    callback('Start Date must be before end date.');
                  } else if (startMoment.isBefore(moment(moment().format('YYYY-MM-DD'), 'YYYY-MM-DD', true))) {
                    callback('Start Date must be today or in the future.');
                  } else {
                    callback();
                  }
                },
              }],
            })(
              <DatePicker allowClear={false} />,
            )}
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="End Date" {...formItemLayout}>
            {getFieldDecorator('calendarEndDate', {
              rules: [{
                required: true,
                message: 'End Date is required.',
              },
              {
                validator: (rule, endMoment, callback) => {
                  // console.log('Rule', rule);
                  if (!endMoment || !endMoment.isValid()) {
                    callback('End Date is not valid.');
                  } else if (endMoment.isBefore(moment(calendarStartDate))) {
                    callback('End Date must be after Start Date.');
                  } else if (endMoment.isSameOrBefore(moment())) {
                    callback('End Date must be in the future.');
                  } else {
                    callback();
                  }
                },
              }],
            })(
              <DatePicker allowClear={false} />,
            )}
          </Form.Item>
        </Col>
      </Row>
      <Divider style={{ margin: 0 }} />
      <Row>
        <Col span={8}>
          <Form.Item label="Checkin Required" {...formItemLayout}>
            {getFieldDecorator('checkinRequired', {
              valuePropName: 'checked',
            })(
              <Switch />,
            )}
          </Form.Item>
        </Col>
        <Col span={16}>
          <Form.Item label="Not Required Reason" {...formItemLayout}>
            {getFieldDecorator('checkinNotRequiredReason', {
              rules: [{
                required: !checkinRequired,
                message: 'Reason required if no checkin required.',
              }],
            })(
              <Input disabled={checkinRequired} />,
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <Form.Item label="Override Time" {...formItemLayout}>
            {getFieldDecorator('checkinOverrideTime', {
              valuePropName: 'checked',
            })(
              <Switch disabled={!checkinRequired} />,
            )}
          </Form.Item>

        </Col>
        <Col span={8}>
          <Form.Item label="Earliest Time" {...formItemLayout}>
            {getFieldDecorator('checkinStartTime', {
              rules: [{
                required: checkinOverrideTime,
                message: 'Earliest time required if overriden.',
              },
              {
                validator: (rule, startMoment, callback) => {
                  // console.log('Rule', rule);
                  if (startMoment && startMoment.isValid()
                    && startMoment.isSameOrAfter(checkinEndTime)) {
                    callback('Earliest Time must be before Latest Time.');
                  } else {
                    callback();
                  }
                },
              }],
            })(
              <TimePicker format="hh:mm A" minuteStep={15} use12Hours disabled={!checkinOverrideTime} allowClear={checkinOverrideTime} />,
            )}
          </Form.Item>

        </Col>
        <Col span={8}>
          <Form.Item label="Latest Time" {...formItemLayout}>
            {getFieldDecorator('checkinEndTime', {
              rules: [{
                required: checkinOverrideTime,
                message: 'Latest time required if overriden.',
              },
              {
                validator: (rule, endMoment, callback) => {
                  // console.log('Rule', rule);
                  if (endMoment && endMoment.isValid()
                    && endMoment.isSameOrBefore(checkinStartTime)) {
                    callback('Latest Time must be after Earliest Time.');
                  } else {
                    callback();
                  }
                },
              }],
            })(
              <TimePicker format="hh:mm A" minuteStep={15} use12Hours disabled={!checkinOverrideTime} allowClear={checkinOverrideTime} />,
            )}
          </Form.Item>

        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <Form.Item label="Sample Required" {...formItemLayout}>
            {getFieldDecorator('sampleRequired', {
              valuePropName: 'checked',
            })(
              <Switch disabled={!checkinRequired} />,
            )}
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Appointment Start" {...formItemLayout}>
            {getFieldDecorator('sampleStartTime', {
              rules: [{
                required: sampleRequired,
                message: 'Start time required if sample required.',
              },
              {
                validator: (rule, startMoment, callback) => {
                  // console.log('Rule', rule);
                  if (startMoment && startMoment.isValid()
                    && startMoment.isSameOrAfter(sampleEndTime)) {
                    callback('Appointment Start must be before Appointment End.');
                  } else {
                    callback();
                  }
                },
              }],
            })(
              <TimePicker format="hh:mm A" minuteStep={15} use12Hours disabled={!sampleRequired} allowClear={sampleRequired} />,
            )}
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Appointment End" {...formItemLayout}>
            {getFieldDecorator('sampleEndTime', {
              rules: [{
                required: sampleRequired,
                message: 'Start time required if sample required.',
              },
              {
                validator: (rule, endMoment, callback) => {
                  // console.log('Rule', rule);
                  if (endMoment && endMoment.isValid()
                    && endMoment.isSameOrBefore(sampleStartTime)) {
                    callback('Appointment End must be after Appointment Start.');
                  } else {
                    callback();
                  }
                },
              }],
            })(
              <TimePicker format="hh:mm A" minuteStep={15} use12Hours disabled={!sampleRequired} allowClear={sampleRequired} />,
            )}
          </Form.Item>
        </Col>
      </Row>

      <Form.Item label="Instructions" {...formItemLayout}>
        {getFieldDecorator('sampleInstructions', {
          // rules: [{
          //   required: breachOccurred,
          //   message: 'Breach Note must be specified if Breach Occurred.',
          // }],
        })(
          <Input.TextArea rows={6} disabled={!sampleRequired} />,
        )}
      </Form.Item>
      <Row>
        <Col span={24} style={{ textAlign: 'right' }}>
          <Button
            icon="rollback"
            onClick={onCancel}
            disabled={!canCancel}
          >
            Cancel
          </Button>
          <Button
            style={{ marginLeft: 8 }}
            type="primary"
            icon="save"
            loading={isProcessing}
            onClick={() => onSave(props.form)}
            disabled={!canSave}
          >
            Save
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default Form.create({
  name: 'BatchPlanForm',
  mapPropsToFields(props) {
    return {
      clientId: Form.createFormField(props.formState.clientId),
      clientName: Form.createFormField(props.formState.clientName),
      calendarStartDate: Form.createFormField(props.formState.calendarStartDate),
      calendarEndDate: Form.createFormField(props.formState.calendarEndDate),
      checkinRequired: Form.createFormField(props.formState.checkinRequired),
      checkinNotRequiredReason: Form.createFormField(props.formState.checkinNotRequiredReason),
      checkinOverrideTime: Form.createFormField(props.formState.checkinOverrideTime),
      checkinStartTime: Form.createFormField(props.formState.checkinStartTime),
      checkinEndTime: Form.createFormField(props.formState.checkinEndTime),
      sampleRequired: Form.createFormField(props.formState.sampleRequired),
      sampleStartTime: Form.createFormField(props.formState.sampleStartTime),
      sampleEndTime: Form.createFormField(props.formState.sampleEndTime),
      sampleInstructions: Form.createFormField(props.formState.sampleInstructions),
    };
  },
  onFieldsChange(props, formState) {
    props.onFieldChange(formState);
  },
})(BatchPlanForm);
