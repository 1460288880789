
const sendMessageImpl = (text, cb) => {
  cb(null, { from: 'User', message: text, date: new Date() });
};

const sendMessage = (text, cb) => {
  setTimeout(() => sendMessageImpl(text, cb), 1000);
};

let unread = 2;

const getUnreadMessageCountImpl = (cb) => {
  return cb(null, unread);
};

const getUnreadMessageCount = (cb) => {
  setTimeout(() => getUnreadMessageCountImpl(cb), 1000);
};

const getCleanDaysImpl = (cb) => {
  const result = { 
    cleanDays: 98,
    cleanDate: new Date(),
  };
  return cb(null, result);
};

const getCleanDays = (cb) => {
  setTimeout(() => getCleanDaysImpl(cb), 1000);
};

let checkinRecord = {
  date: new Date(),
  checkedIn: false,
}

const checkinImpl = (cb) => {
  // date, day, checkedIn, sampleLocation, sampleTime, sampleNote
  checkinRecord = {
    date: new Date(),
    checkedIn: true,
    sampleRequired: true,
    sampleLocation: 'Prince George Office',
    sampleTime: '9AM-11AM',
    sampleNote: 'Please bring your new ... ',
  };

  return cb(null, checkinRecord);
};

const checkin = (cb) => {
  setTimeout(() => checkinImpl(cb), 1000);
};

const getCheckinImpl = (cb) => {
  return cb(null, checkinRecord);
};

const getCheckin = (cb) => {
  setTimeout(() => getCheckinImpl(cb), 2000);
};

const getMessagesImpl = (cb) => {
  unread = 0;
  const messages = [
    { from: 'Teila', message: 'We should probably do the thing with the thing', date: '2018-08-28 10:02 AM' },
    { from: 'Bob', message: 'We should probably do the thing with the thing', date: '2018-08-28 10:04 AM' },
    { from: 'Bob', message: 'We should probably do the thing with the thing', date: '2018-08-28 10:06 AM' },
    { from: 'Teila', message: 'We should probably do the thing with the thing', date: '2018-08-28 10:08 AM' },
  ];
  const result = {
    messages,
    more: 1,
  };
  return cb(null, result);
};

const getMessages = (cb) => {
  setTimeout(() => getMessagesImpl(cb), 1500);
};

const getMoreMessagesImpl = (moreKey, cb) => {
  let messages;
  let more;

  if (moreKey === 1) {
    messages = [
      { from: 'Teila', message: 'More messages 1', date: '2018-08-28 10:02 AM' },
      { from: 'Teila', message: 'More messages 1', date: '2018-08-28 10:02 AM' },
      { from: 'Teila', message: 'More messages 1', date: '2018-08-28 10:02 AM' },
    ];
    more = 2;
  } else {
    messages = [
      { from: 'Teila', message: 'More messages 2', date: '2018-08-28 10:02 AM' },
      { from: 'Teila', message: 'More messages 2', date: '2018-08-28 10:02 AM' },
    ];
    more = null;
  }

  const result = {
    messages,
    more,
  };
  return cb(null, result);
};

const getMoreMessages = (moreKey, cb) => {
  setTimeout(() => getMoreMessagesImpl(moreKey, cb), 1500);
};

module.exports = {
  sendMessage,
  getMessages,
  getMoreMessages,
  getUnreadMessageCount,
  getCleanDays,
  getCheckin,
  checkin,
};
