import { Auth } from 'aws-amplify';

export const LOGOUT = 'LOGOUT';

export function logout() {
  return () => {
    Auth.signOut()
      .then(data => console.log('Logout Then', data))
      .catch(err => console.log('Logout Err', err));
  };
}
