import React from 'react';
import FormModal from '../../Components/FormModal';
import BreachForm from './BreachForm';

const BreachModal = props => (
  <FormModal title="Breach" {...props}>
    <BreachForm {...props} />
  </FormModal>
);

export default BreachModal;
