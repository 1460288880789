/* global fetch:false */
import { Auth } from 'aws-amplify';
import { stringify } from 'query-string';

const api2 = (path, options = {}) => new Promise(async (resolve, reject) => {
  // const url = 'doesnotexist.com' + path;
  const url = process.env.REACT_APP_API_URL + path;

  const user = await Auth.currentAuthenticatedUser({
    bypassCache: false,
  });
  const token = user.signInUserSession.idToken.jwtToken;

  const effOptions = Object.assign({}, options, {
    headers: Object.assign({}, options.headers, {
      Accept: 'application/json',
      Authorization: token,
    }),
  });
  // const effHeaders = Object.assign({}, options.headers, )
  // options.headers = {
  //   'Accept': 'application/json',
  //   'Authorization': token,
  //   'Test': 'abc',
  // };

  fetch(url, effOptions)
    .then(async (response) => {
      if (response.status === 200) {
        return resolve(response.json());
      }

      const error = new Error('API Call Failed');
      error.url = url;
      error.status = response.status;
      // error.uuid = 'WHAT?';

      if (response.headers.get('Content-Type').startsWith('application/json')) {
        error.body = await response.json();
      } else {
        error.body = {
          message: `Unable to contact server (${response.statusText}).`,
          uuid: `HTTP_${response.status}`,
        };
      }
      console.log('inner error', error, error.body);
      return reject(error);
    });
});

export const myClient = () => api2('/client/');


export const listClients = qs => api2(`/office/clients/?${stringify(qs)}`);
export const getClient = patientId => api2(`/office/clients/${patientId}`);
export const getClientSchedule = (patientId, qs) => api2(`/office/clients/${patientId}/schedules/?${stringify(qs)}`);
export const getSchedule = (calendarDate, qs) => api2(`/office/schedules/${calendarDate}?${stringify(qs)}`);
export const getOverdue = qs => api2(`/office/schedules/overdue?${stringify(qs)}`);
export const getPlanningSchedule = (calendarDate, qs) => api2(`/office/clients/schedules?${stringify(qs)}`);
export const upsertClient = (patient) => {
  const patientId = patient.id;
  const updPatient = patient;
  updPatient.archived = !patient.statusActive;
  updPatient.imePerformed = patient.imePerformed || false;
  const url = (patientId ? `/office/clients/${patientId}` : '/office/clients/');
  return api2(url, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(updPatient),
  });
};
export const uploadPicture = (patientId, fileB64) => {
  const url = `/office/clients/${patientId}/profilePicture`;
  return api2(url, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    body: `{ "image": "${fileB64}" }`,
  });
};

// function parseJSON(response) {
//   return new Promise(resolve => response.json()
//     .then(json => resolve({
//       status: response.status,
//       ok: response.ok,
//       json,
//     })));
// }

// function api(url, options) {
//   return new Promise((resolve, reject) => {
//     fetch(url, options)
//       .then(parseJSON)
//       .then((response) => {
//         if (response.ok) {
//           return resolve(response.json);
//         }
//         // extract the error from the server's json
//         return reject(response.json);
//       })
//       .catch(error => reject({
//         networkError: error.message,
//       }));
//   });
// }

export const getClientCheckin = () => api2('/client/checkin');

export const clientCheckin = () => api2('/client/checkin', {
  method: 'POST',
  mode: 'cors',
  headers: {
    'Content-Type': 'application/json',
  },
  body: JSON.stringify({}),
});

export const postManualCheckin = ({
  clientId,
  calendarDate,
}, body) => api2(`/office/clients/${clientId}/schedules/${calendarDate}/checkin`, {
  method: 'POST',
  mode: 'cors',
  headers: {
    'Content-Type': 'application/json',
  },
  body: JSON.stringify(body),
});

export const postScreening = ({
  clientId,
  calendarDate,
}, body) => api2(`/office/clients/${clientId}/schedules/${calendarDate}/screening`, {
  method: 'POST',
  mode: 'cors',
  headers: {
    'Content-Type': 'application/json',
  },
  body: JSON.stringify(body),
});

export const postForensic = ({
  clientId,
  calendarDate,
}, body) => api2(`/office/clients/${clientId}/schedules/${calendarDate}/forensic`, {
  method: 'POST',
  mode: 'cors',
  headers: {
    'Content-Type': 'application/json',
  },
  body: JSON.stringify(body),
});

export const postBreach = ({
  clientId,
  calendarDate,
}, body) => api2(`/office/clients/${clientId}/schedules/${calendarDate}/breach`, {
  method: 'POST',
  mode: 'cors',
  headers: {
    'Content-Type': 'application/json',
  },
  body: JSON.stringify(body),
});

export const postPlan = ({
  clientId,
  calendarDate,
}, body) => api2(`/office/clients/${clientId}/schedules/${calendarDate}/plan`, {
  method: 'POST',
  mode: 'cors',
  headers: {
    'Content-Type': 'application/json',
  },
  body: JSON.stringify(body),
});

export const postBatchPlan = ({
  clientId,
}, body) => api2(`/office/clients/${clientId}/schedules/batchPlan`, {
  method: 'POST',
  mode: 'cors',
  headers: {
    'Content-Type': 'application/json',
  },
  body: JSON.stringify(body),
});

// export default {
//   getClient,
//   getClientSchedule,
//   listClients,
//   upsertClient,
//   uploadPicture,
//   getSchedule,
//   getPlanningSchedule,
//   postManualCheckin,
//   postScreening,
//   postForensic,
//   postBreach,
//   postPlan,
//   getOverdue,

// };
