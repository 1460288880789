import React from 'react';
import CheckinCell from '../Cells/CheckinCell';
import SampleCell from '../Cells/SampleCell';
import ScreeningCell from '../Cells/ScreeningCell';
import ForensicCell from '../Cells/ForensicCell';
import BreachCell from '../Cells/BreachCell';
// import Calendar from 'rc-calendar';
import 'rc-calendar/assets/index.css';
import FullCalendar from 'rc-calendar/lib/FullCalendar';
import 'rc-select/assets/index.css';
import RCSelect from 'rc-select';
import { Icon, DatePicker, Select, Row, Col, Button, Form } from 'antd';
// import moment from 'moment';

const { MonthPicker } = DatePicker;
const Option = Select.Option;

function overviewRender(mom, calendar) {
  const date = mom.format('YYYY-MM-DD');

  const style = {};
  let d = mom.format('D');
  if (d === '1') {
    d = mom.format('MMM D');
  }

  const item = calendar.find(x => x.calendarDate === date);
  let day;
  let breach;
  let content;

  if (item) {
    content = (
      // <table>
      //   <tr>
      //     <td>Ch</td>
      //     <td>Sa</td>
      //     <td>Sc</td>
      //     <td>Fo</td>
      //     <td>Br</td>
      //   </tr>
      //   <tr>
      //     <td><CheckinCell {...item} /></td>
      //     <td><SampleCell {...item} /></td>
      //     <td><ScreeningCell {...item} /></td>
      //     <td><ForensicCell {...item} /></td>
      //     <td><BreachCell {...item} /></td>
      //   </tr>
      //   </table>

      <Row>
        <Col span={4} style={{ textAlign: 'center' }}><CheckinCell {...item} showLabel /></Col>
        <Col span={4} style={{ textAlign: 'center' }}><SampleCell {...item} showLabel /></Col>
        <Col span={4} style={{ textAlign: 'center' }}><ScreeningCell {...item} showLabel /></Col>
        <Col span={4} style={{ textAlign: 'center' }}><ForensicCell {...item} showLabel /></Col>
        <Col span={4} style={{ textAlign: 'center' }}><BreachCell {...item} showLabel /></Col>
      </Row>
    );
    if (item.goodDay) {
      day = 'Good';
      style.color = '#52c41a';
      style.background = '#f6ffed';
      style.borderColor = '#b7eb8f';
    } else {
      day = 'Bad';
      style.color = '#f5222d';
      style.background = '#fff1f0';
      style.borderColor = '#ffa39e';
    }

    if (item.breachOccurred) {
      breach = item.breachType;
    }
  }

  return (// style={style}
    <div className="rc-calendar-date test">
      {d}
      <br />
      <br />
      {content}
    </div>
  );
}


const CalendarTab = (props) => {
  const { date, calendar, onDateChange, onMonthNextClick, onMonthPrevClick } = props;

  return (
    <>
      <Form layout="inline">
        <Form.Item>
          <Button onClick={onMonthPrevClick} icon="left" />
          <MonthPicker style={{ width: 100 }} value={date} allowClear={false} onChange={onDateChange} />
          <Button onClick={onMonthNextClick} icon="right" />
        </Form.Item>
      </Form>

      <div>
      <FullCalendar
        className="test"
        mode="month"
        fullscreen
        // onSelect={onSelect}
        Select={RCSelect}
        // onChange={onDateChange}
        value={date}
        dateCellRender={mom => overviewRender(mom, calendar)}
        />
        </div>
    </>
  );
};

export default CalendarTab;
