import moment from 'moment';

import {
  SET_PLANNER_SEARCH_TEXT,
  SET_PLANNER_DATE,
  NEXT_PLANNER_DATE,
  PREV_PLANNER_DATE,
  SET_PLANNER_PAGE,
  REQUEST_PLANNER,
  RECEIVE_PLANNER,
} from '../actions';

const initialState = {
  searchText: '',
  loading: false,
  error: '',
  calendarDate: moment().format('YYYY-MM-DD'),
  schedule: [],
  offset: 0,
  limit: 10,
  count: 0,
};

export default function planner(state = initialState, action) {
  switch (action.type) {
    case SET_PLANNER_SEARCH_TEXT:
      return Object.assign({}, state, { searchText: action.payload });
    case SET_PLANNER_DATE:
      return Object.assign({}, state, { calendarDate: action.payload });
    case NEXT_PLANNER_DATE:
      console.log('Inside NEXT_PLANNER_DATE reducer');
      return Object.assign({}, state, { calendarDate: moment(state.calendarDate).add(+1, 'day').format('YYYY-MM-DD') });
    case PREV_PLANNER_DATE:
      return Object.assign({}, state, { calendarDate: moment(state.calendarDate).add(-1, 'day').format('YYYY-MM-DD') });
    case SET_PLANNER_PAGE:
      return Object.assign({}, state, { ...action.payload });
    case REQUEST_PLANNER:
      return Object.assign({}, state, { loading: true });
    case RECEIVE_PLANNER:
      if (action.payload.error) {
        return Object.assign({}, state, { error: action.payload.error, loading: false, schedule: [] });
      }
      return Object.assign({}, state, { error: '', loading: false, schedule: action.payload.items, count: action.payload.count });
    default:
      return state;
  }
}
