import React from 'react';
import { Popover, Icon } from 'antd';
import time24to12 from '../../Common/time24to12';

const SAMPLE_NOT_NEEDED = 'Not Needed';
const SAMPLE_COMPLETE = 'Complete';
const SAMPLE_MISSED = 'Missed';
const SAMPLE_PENDING = 'Pending';

const SampleCell = (props) => {
  const {
    sampleStatus,
    sampleMissedTime,
    sampleStartTime,
    sampleEndTime,
    showLabel,
  } = props;

  let content;
  let icon;

  switch (sampleStatus) {
    case SAMPLE_COMPLETE:
      content = 'Received';
      icon = <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" />;
      break;
    case SAMPLE_MISSED:
      content = `Marked as missed at ${time24to12(sampleMissedTime)}`;
      icon = <Icon type="close-circle" theme="twoTone" twoToneColor="red" />;
      break;
    case SAMPLE_PENDING:
      content = `Pending: between ${time24to12(sampleStartTime)} and ${time24to12(sampleEndTime)}`;
      icon = <Icon type="question-circle" />;
      break;
    case SAMPLE_NOT_NEEDED:
      return null;
    default:
      throw new Error(`Unexpected sampleStatus: ${sampleStatus}`);
  }

  const label = showLabel ? (icon ? <><span>Sa</span><br /></> : <><span></span><br /></>) : null;

  return (
    <Popover title="Sample" content={content} trigger="click">
      {label}
      {icon}
    </Popover>
  );
};


export default SampleCell;
