import moment from 'moment';

import {
  SET_OVERDUE_SEARCH_TEXT,
  SET_OVERDUE_PAGE,
  SET_OVERDUE_PAGE_SIZE,
  SET_OVERDUE_DATE,
  REQUEST_OVERDUE,
  RECEIVE_OVERDUE_SUCCESS,
  RECEIVE_OVERDUE_FAILURE,
} from '../actions/overdueActions';

const initialState = {
  searchText: '',
  loading: false,
  error: '',
  calendarDate: moment().format('YYYY-MM-DD'),
  dates: [],
  pageSize: 10,
  page: 1,
  count: 0,
};

export default function daily(state = initialState, action) {
  switch (action.type) {
    case SET_OVERDUE_SEARCH_TEXT:
    case SET_OVERDUE_DATE:
    case SET_OVERDUE_PAGE:
    case SET_OVERDUE_PAGE_SIZE:
    case REQUEST_OVERDUE:
    case RECEIVE_OVERDUE_SUCCESS:
    case RECEIVE_OVERDUE_FAILURE:
      return Object.assign({}, state, { ...action.payload });
    default:
      return state;
  }
}
