import React from 'react';
import FormModal from '../../Components/FormModal';
import ForensicForm from './ForensicForm';

const ForensicModal = props => (
  <FormModal title="Forensic" {...props}>
    <ForensicForm {...props} />
  </FormModal>
);

export default ForensicModal;
