import React, { Component } from 'react';
import { Spin } from 'antd';

import Page from '../Common/Page';

class LogoutPage extends Component {
  componentDidMount() {
    const { onLogout } = this.props;
    onLogout();
  }

  render() {
    const { logoutProcessing } = this.props;

    return (
      <Page title="Logout">
        <Spin spinning={logoutProcessing}>
          {/* {success && <Alert message="Password Reset Email Sent" description={success} type="success" showIcon />}
          {error && <Alert message="Change Password Failed" description={desc} type="error" showIcon />}
          {!success && !error && (
            <Card>
              <p>To change your password click the below button.</p>
              <p><Button type="primary" onClick={onChangePasswordClick}>Change Password</Button></p>
            </Card>
          )} */}
        </Spin>
      </Page>
    );
  }
}

export default LogoutPage;
