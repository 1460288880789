import React from 'react';
import { Popover, Icon } from 'antd';

const FORENSIC_POSITIVE = 'Positive';
const FORENSIC_NEGATIVE = 'Negative';
const FORENSIC_PENDING = 'Pending';
const FORENSIC_NOT_NEEDED = 'Not Needed';

const ForensicCell = (props) => {
  const {
    forensicStatus,
    showLabel,
  } = props;

  let content;
  let icon;

  switch (forensicStatus) {
    case FORENSIC_POSITIVE:
      content = 'Positive';
      icon = <Icon type="exclamation-circle" theme="twoTone" twoToneColor="red" />;
      break;
    case FORENSIC_NEGATIVE:
      content = 'Negative';
      icon = <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" />;
      break;
    case FORENSIC_PENDING:
      content = 'Pending';
      icon = <Icon type="question-circle" />;
      break;
    case FORENSIC_NOT_NEEDED:
      return null;
    default:
      throw new Error(`Unexpected forensicStatus: ${forensicStatus}`);
  }

  const label = showLabel ? (icon ? <><span>Fo</span><br /></> : <><span></span><br /></>) : null;

  return (
    <Popover title="Forensic Result" content={content} trigger="click">
      {label}
      {icon}
    </Popover>
  );
};

export default ForensicCell;
