import { connect } from 'react-redux';

import ScreeningModal from './ScreeningModal';

import {
  screeningModalClose,
  screeningModalSave,
  screeningModalSetField,
} from '../../redux/actions/screeningActions';

const mapStateToProps = state => ({
  ...state.screeningModal,
});

const mapDispatchToProps = dispatch => ({
  onCancel() {
    dispatch(screeningModalClose());
  },
  onSave(form) {
    dispatch(screeningModalSave(form));
  },
  onFieldChange(formState) {
    dispatch(screeningModalSetField(formState));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ScreeningModal);
