import React from 'react';
import { Alert, Modal } from 'antd';

const FormModal = (props) => {
  const { title, show, error, width } = props;
  return (
    <Modal
      visible={show}
      title={title}
      // Force the user to close the modal by clicking a specific button in the footer
      // rather than an ambigious close button or clicking outside the modal.
      closable={false}
      maskClosable={false}
      // width={width}
      footer={null}
    >
      { error && (
        <Alert
          message={error.message}
          description={error.description}
          type="error"
        />
      ) }
      {props.children}
    </Modal>
  );
};

export default FormModal;
