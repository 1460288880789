import { connect } from 'react-redux';
import App from './App';

import {
  logout,
} from './redux/actions/appActions';

const mapStateToProps = state => state.username;

const mapDispatchToProps = dispatch => ({
  // username: this.username,
  onLogoutClick() {
    dispatch(logout());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(App);
