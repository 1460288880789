import { Icon, PageHeader } from 'antd';
import React from 'react';
import icon from '../../Common/rr-icon-green.ico';

const Page = ({ title, children }) => (
  <div className="content-container">
    <PageHeader title={<><img className="page-header-icon" src={icon} /><span style={{ paddingLeft: 8, fontWeight: 'normal' }}>{title}</span></>} />
    <div className="content-body">
      {children}
    </div>
  </div>
);

export default Page;
