import React from 'react';
import { Card } from 'antd';

const PhoneCard = ({
  phone,
}) => (
  <Card>
    <div>
      <span className="highlight">Office Phone:</span>
      <p><a href={`tel:+1${phone}`}>{phone}</a></p>
    </div>
    <div>
      <span className="highlight">Note:</span>
      <p>If phone is not answered, leave a voicemail as it is checked on a regular basis.</p>
    </div>
  </Card>
);

export default PhoneCard;
