import React from 'react';
import { Button, Form, Input } from 'antd';
import { Link } from 'react-router-dom';

import StatusSelect from '../Components/StatusSelect';

const ClientListingFilter = (props) => {
  const {
    searchText,
    onSearchChange,
    onSearch,
    status,
    onStatusChange,
    style,
  } = props;

  return (
    <Form layout="inline" style={style}>
      <Form.Item>
        <Link to="/clients/new"><Button type="primary" icon="user-add">New Client</Button></Link>
      </Form.Item>
      <Form.Item>
        <Input.Search placeholder="search last name" value={searchText} onChange={onSearchChange} onSearch={onSearch} />
      </Form.Item>
      <Form.Item>
        <StatusSelect value={status} onChange={onStatusChange} />
      </Form.Item>
    </Form>
  );
};

export default ClientListingFilter;
