import { connect } from 'react-redux';

import ClientListing from './ClientListing';

import {
  setClientListingSearchText,
  setClientListingStatus,
  getClientListing,
  setClientListingPage,
  setClientListingPageSize,
} from '../redux/actions/clientListingActions';

const mapStateToProps = state => ({
  ...state.clientListing,
});

const mapDispatchToProps = dispatch => ({
  onPageChange(page) {
    dispatch(setClientListingPage(page));
    dispatch(getClientListing());
  },
  onPageSizeChange(_page, pageSize) {
    dispatch(setClientListingPageSize(pageSize));
    dispatch(getClientListing());
  },
  onSearchChange: (e) => {
    dispatch(setClientListingSearchText(e.target.value));
  },
  onSearch() {
    dispatch(getClientListing());
  },
  onStatusChange: (value) => {
    dispatch(setClientListingStatus(value));
    dispatch(getClientListing());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ClientListing);
