import {
  SCREENING_MODAL_OPEN,
  SCREENING_MODAL_CLOSE,
  SCREENING_MODAL_SAVING_START,
  SCREENING_MODAL_SAVING_SUCCESS,
  SCREENING_MODAL_SAVING_FAILURE,
  SCREENING_MODAL_SET_FIELD,
} from '../actions/screeningActions';

const initialState = {
  formState: { },
  isProcessing: false,
  error: null,
  show: false,
  canSave: false,
  canCancel: true,
};

export default function breachModal(state = initialState, action) {
  switch (action.type) {
    case SCREENING_MODAL_OPEN:
    case SCREENING_MODAL_CLOSE:
    case SCREENING_MODAL_SAVING_START:
    case SCREENING_MODAL_SAVING_SUCCESS:
    case SCREENING_MODAL_SAVING_FAILURE:
      return Object.assign({}, state, { ...action.payload });
    case SCREENING_MODAL_SET_FIELD:
      return Object.assign({}, state, {
        formState: Object.assign({}, state.formState, { ...action.payload.formState }),
      });
    default:
      return state;
  }
}
