import {
  SET_CLIENT_LISTING_SEARCH_TEXT,
  SET_CLIENT_LISTING_STATUS,
  SET_CLIENT_LISTING_PAGE,
  SET_CLIENT_LISTING_PAGE_SIZE,
  REQUEST_CLIENT_LISTING,
  RECEIVE_CLIENT_LISTING_SUCCESS,
  RECEIVE_CLIENT_LISTING_FAILURE,
} from '../actions/clientListingActions';

const initialState = {
  searchText: '',
  status: 'active',
  loading: false,
  error: '',
  clients: [],
  statusOptions: [
    { text: 'Active Only', value: 'active' },
    { text: 'Archived Only', value: 'archived' },
    { text: 'Active and Archived', value: 'all' },
  ],
  pageSize: 10,
  page: 1,
  count: 0,
};

export default function clientListing(state = initialState, action) {
  switch (action.type) {
    case SET_CLIENT_LISTING_SEARCH_TEXT:
    case SET_CLIENT_LISTING_STATUS:
    case SET_CLIENT_LISTING_PAGE:
    case SET_CLIENT_LISTING_PAGE_SIZE:
    case REQUEST_CLIENT_LISTING:
    case RECEIVE_CLIENT_LISTING_SUCCESS:
    case RECEIVE_CLIENT_LISTING_FAILURE:
      return Object.assign({}, state, { ...action.payload });
    default:
      return state;
  }
}
