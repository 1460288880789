import Amplify, { Auth } from 'aws-amplify';
import { withAuthenticator, ConfirmSignIn, ForgotPassword, RequireNewPassword, SignIn, VerifyContact } from 'aws-amplify-react';
import React, { Component } from 'react';
import { withRouter } from 'react-router';

import ClientController from './Client/ClientController';
import OfficeController from './Office/OfficeController';

Amplify.configure({
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: process.env.REACT_APP_COGNITO_REGION,

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_CLIENT_ID,
  },
});

const getUser = async () => {
  const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
  console.log('AppController.jsx User', user);
  const { username } = user;
  const groups = user.signInUserSession.accessToken.payload['cognito:groups'];
  const isPasswordChangeRequired = false; // Auth.isPasswordChangeRequired();

  if (!groups || groups.length === 0) {
    return {
      role: 'Client',
      username,
      isPasswordChangeRequired,
    };
  }

  if (groups.length > 1) {
    throw new Error('More than one group found', groups);
  }

  const group = groups[0];

  if (group === 'Admin') {
    return {
      role: 'Office',
      username,
      isPasswordChangeRequired,
    };
  }

  throw new Error('Unexpected group', groups[0]);
};

class AppController extends Component {
  constructor(props) {
    super(props);

    this.state = {
      changePasswordProcessing: false,
      changePasswordError: null,
      changePasswordSuccess: false,

    };

    this.onChangePassword = this.onChangePassword.bind(this);
    this.onLogout = this.onLogout.bind(this);
  }

  async componentDidMount() {
    try {
      const { username, role, isPasswordChangeRequired } = await getUser();

      this.setState({
        username,
        role,
        isPasswordChangeRequired,
      });
    } catch (e) {
      this.setState({
        username: null,
        role: null,
        isPasswordChangeRequired: null,
      });
    }
  }

  /**
   * Called when a user wants to change their password.
   */
  onChangePassword() {
    this.setState({
      changePasswordProcessing: true,
    }, () => {
      Auth.changePassword((err) => {
        if (err) {
          console.error('An error occured while trying to change password (onChangePassword).', err);
        }
        this.setState({
          changePasswordProcessing: false,
          changePasswordError: err,
          changePasswordSuccess: err ? null : 'An email has been sent to you with instructions. You should receive it within 5 minutes.',
        });
      });
    });
  }

  async onLogout() {
    this.props.history.push('/');
    await Auth.signOut();
  }

  render() {
    const { role } = this.state;

    if (role === 'Client') {
      return <ClientController {...this.state} onChangePassword={this.onChangePassword} onLogout={this.onLogout} />;
    } if (role === 'Office') {
      return <OfficeController {...this.state} onChangePassword={this.onChangePassword} onLogout={this.onLogout} />;
    }
    return <div>Not logged in</div>;
  }
}

export default withAuthenticator(withRouter(AppController), false, [
  <SignIn />,
  <ConfirmSignIn />,
  <VerifyContact />,
  <ForgotPassword />,
  <RequireNewPassword />,
]);
