import moment from 'moment';

import { postManualCheckin } from '../../../Common/api';

import {
  modalOpen,
  modalClose,
  modalSetField,
  modalSavingStart,
  modalSavingSuccess,
  modalSavingFailure,
} from './modal_actions';

export const CHECKIN_MODAL_OPEN = 'CHECKIN_MODAL_OPEN';
export const CHECKIN_MODAL_CLOSE = 'CHECKIN_MODAL_CLOSE';
export const CHECKIN_MODAL_SET_FIELD = 'CHECKIN_MODAL_SET_FIELD';
export const CHECKIN_MODAL_SAVING_START = 'CHECKIN_MODAL_SAVING_START';
export const CHECKIN_MODAL_SAVING_SUCCESS = 'CHECKIN_MODAL_SAVING_SUCCESS';
export const CHECKIN_MODAL_SAVING_FAILURE = 'CHECKIN_MODAL_SAVING_FAILURE';

export function checkinModalOpen(data, onSave) {
  const formState = {
    clientId: { value: data.client.id },
    clientName: { value: data.client.name },
    calendarDate: { value: moment(data.calendarDate, 'YYYY-MM-DD') },
    checkinTime: { value: data.checkinTime ? moment(data.checkinTime, 'HH:mm') : null },
    // checkinTime: { value: data.checkinTime },
    checkinSource: { value: data.checkinSource },
  };

  return modalOpen(CHECKIN_MODAL_OPEN, formState, onSave);
}

export function checkinModalClose() {
  return modalClose(CHECKIN_MODAL_CLOSE);
}

export function checkinModalSetField(formState) {
  return modalSetField(CHECKIN_MODAL_SET_FIELD, formState);
}

export function checkinModalSavingStart() {
  return modalSavingStart(CHECKIN_MODAL_SAVING_START);
}

export function checkinModalSavingSuccess() {
  return modalSavingSuccess(CHECKIN_MODAL_SAVING_SUCCESS);
}

export function checkinModalSavingFailure(error) {
  return modalSavingFailure(CHECKIN_MODAL_SAVING_FAILURE, 'Unable to save checkin', error);
}

export function checkinModalSave(form) {
  return (dispatch, getState) => {
    const { checkinModal } = getState();
    const { onSave } = checkinModal;

    form.validateFields({ force: true }, (err, values) => {
      // If there are errors then cancel the save.
      // Any errors will be displayed per field on the form.
      if (err) {
        return;
      }

      const calendarDate = values.calendarDate.format('YYYY-MM-DD');
      const checkinTime = values.checkinTime.format('HH:mm');

      const {
        clientId,
        checkinReceived,
        checkinSource,
        checkinMissed,
        checkinMissedTime,
      } = values;

      dispatch(checkinModalSavingStart());

      postManualCheckin(
        { clientId, calendarDate },
        { checkinReceived, checkinTime, checkinSource, checkinMissed, checkinMissedTime },
      )
        .then((obj) => {
          dispatch(checkinModalSavingSuccess(obj));
          dispatch(checkinModalClose());
          onSave();
        })
        .catch((apiErr) => {
          dispatch(checkinModalSavingFailure(apiErr));
        });
    });
  };
}
