import React from 'react';
import { DatePicker, Form, Input, Button, Select, Row, Col, TimePicker } from 'antd';

const CheckinForm = (props) => {
  const { canSave, canCancel, onSave, onCancel, isProcessing, form } = props;
  const { getFieldDecorator } = form;

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  return (
    <Form layout="vertical">
      <Form.Item label="Client" {...formItemLayout}>
        {getFieldDecorator('clientName', {
        })(
          <Input disabled />,
        )}
      </Form.Item>
      <Form.Item label="Client Id" {...formItemLayout} style={{ display: 'none' }}>
        {getFieldDecorator('clientId', {
        })(
          <Input disabled />,
        )}
      </Form.Item>
      <Form.Item label="Date" {...formItemLayout}>
        {getFieldDecorator('calendarDate', {
        })(
          <DatePicker disabled />,
        )}
      </Form.Item>
      <Form.Item label="Checkin Time" {...formItemLayout}>
        {getFieldDecorator('checkinTime', {})(
          <TimePicker format="hh:mm A" use12Hours />,
        )}
      </Form.Item>
      <Form.Item label="Checkin Source" {...formItemLayout}>
        {getFieldDecorator('checkinSource', {})(
          <Select disabled={false}>
            <Select.Option value="In-Person">In-Person</Select.Option>
            <Select.Option value="Phone">Phone</Select.Option>
          </Select>,
        )}
      </Form.Item>
      <Row>
        <Col span={24} style={{ textAlign: 'right' }}>
          <Button
            icon="rollback"
            onClick={onCancel}
            disabled={!canCancel}
          >
            Cancel
          </Button>
          <Button
            style={{ marginLeft: 8 }}
            type="primary"
            icon="save"
            loading={isProcessing}
            onClick={() => onSave(props.form)}
            disabled={!canSave}
          >
            Save
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default Form.create({
  name: 'CheckinForm',
  mapPropsToFields(props) {
    return {
      clientId: Form.createFormField(props.formState.clientId),
      clientName: Form.createFormField(props.formState.clientName),
      calendarDate: Form.createFormField(props.formState.calendarDate),
      checkinTime: Form.createFormField(props.formState.checkinTime),
      checkinSource: Form.createFormField(props.formState.checkinSource),
    };
  },
  onFieldsChange(props, formState) {
    props.onFieldChange(formState);
  },
})(CheckinForm);
