import React from 'react';
import { DatePicker, Form, Input, Button, Select, Row, Col, Switch, Alert } from 'antd';

const ForensicForm = (props) => {
  const { canSave, canCancel, onSave, onCancel, isProcessing, form } = props;
  const { getFieldDecorator } = form;

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  const { formState: {
    screeningResult: { value: screeningResult },
    forensicRequired: { value: forensicRequired },
  } } = props;

  return (
    <Form layout="vertical">
      {!screeningResult && <Alert type="info" message="Screening Result not recorded for this day, so Forensic data cannot be selected." showIcon style={{ marginBottom: '16px' }} ></Alert>}
      <Form.Item label="Client" {...formItemLayout}>
        {getFieldDecorator('clientName', {
        })(
          <Input disabled />,
        )}
      </Form.Item>
      <Form.Item label="Client Id" {...formItemLayout} style={{ display: 'none' }}>
        {getFieldDecorator('clientId', {
        })(
          <Input disabled />,
        )}
      </Form.Item>
      <Form.Item label="Date" {...formItemLayout}>
        {getFieldDecorator('calendarDate', {
        })(
          <DatePicker disabled />,
        )}
      </Form.Item>
      <Form.Item label="Screening Result" {...formItemLayout} style={{ display: 'none' }}>
        {getFieldDecorator('screeningResult', {
        })(
          <Input disabled />,
        )}
      </Form.Item>
      <Form.Item label="Forensic Test Required" {...formItemLayout}>
        {getFieldDecorator('forensicRequired', {
          valuePropName: 'checked',
        })(
          <Switch disabled={!screeningResult} />,
        )}
      </Form.Item>
      <Form.Item label="Forensic Lab Result" {...formItemLayout}>
        {getFieldDecorator('forensicResult', {
          rules: [{
            // required: forensicRequired,
            // message: 'Breach Type must be specified if Breach Occurred.',
          }],
        })(
          <Select disabled={!forensicRequired}>
            <Select.Option value="NULL">&nbsp;</Select.Option>
            <Select.Option value="Negative">Negative</Select.Option>
            <Select.Option value="Positive">Positive</Select.Option>
          </Select>,
        )}
      </Form.Item>
      <Row>
        <Col span={24} style={{ textAlign: 'right' }}>
          <Button
            icon="rollback"
            onClick={onCancel}
            disabled={!canCancel}
          >
            Cancel
          </Button>
          <Button
            style={{ marginLeft: 8 }}
            type="primary"
            icon="save"
            loading={isProcessing}
            onClick={() => onSave(props.form)}
            disabled={!canSave || !screeningResult}
          >
            Save
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default Form.create({
  name: 'ForensicForm',
  mapPropsToFields(props) {
    return {
      clientId: Form.createFormField(props.formState.clientId),
      clientName: Form.createFormField(props.formState.clientName),
      calendarDate: Form.createFormField(props.formState.calendarDate),
      screeningResult: Form.createFormField(props.formState.screeningResult),
      forensicRequired: Form.createFormField(props.formState.forensicRequired),
      forensicResult: Form.createFormField(props.formState.forensicResult),
    };
  },
  onFieldsChange(props, formState) {
    props.onFieldChange(formState);
  },
})(ForensicForm);
