import { connect } from 'react-redux';

import BreachModal from './BreachModal';

import {
  breachModalClose,
  breachModalSave,
  breachModalSetField,
} from '../../redux/actions/breachActions';

const mapStateToProps = state => ({
  ...state.breachModal,
});

const mapDispatchToProps = dispatch => ({
  onCancel() {
    dispatch(breachModalClose());
  },
  onSave(form) {
    dispatch(breachModalSave(form));
  },
  onFieldChange(formState) {
    dispatch(breachModalSetField(formState));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BreachModal);
