import moment from 'moment';

import {
  getSchedule,
} from '../../../Common/api';

export const SET_DAILY_SEARCH_TEXT = 'SET_DAILY_SEARCH_TEXT';
export function setDailySearchText(searchText) {
  return {
    type: SET_DAILY_SEARCH_TEXT,
    payload: {
      searchText,
      page: 1,
    },
  };
}

export const SET_DAILY_PAGE = 'SET_DAILY_PAGE';
export function setDailyPage(page) {
  return {
    type: SET_DAILY_PAGE,
    payload: {
      page,
    },
  };
}

export const SET_DAILY_PAGE_SIZE = 'SET_DAILY_PAGE_SIZE';
export function setDailyPageSize(pageSize) {
  return {
    type: SET_DAILY_PAGE_SIZE,
    payload: {
      pageSize,
      page: 1,
    },
  };
}

export const SET_DAILY_DATE = 'SET_DAILY_DATE';
export function setDailyDate(calendarDate) {
  // Dont set the date if it is was invalid.
  if (!moment(calendarDate, 'YYYY-MM-DD').isValid()) {
    return { type: 'CANCEL_ACTION', payload: SET_DAILY_DATE };
  }
  return {
    type: SET_DAILY_DATE,
    payload: {
      calendarDate,
      page: 1,
    },
  };
}

export function nextDailyDate() {
  return (dispatch, getState) => {
    const { calendarDate } = getState().daily;
    const nextDate = moment(calendarDate, 'YYYY-MM-DD').add(1, 'day').format('YYYY-MM-DD');
    dispatch(setDailyDate(nextDate));
  };
}
export function prevDailyDate() {
  return (dispatch, getState) => {
    const { calendarDate } = getState().daily;
    const prevDate = moment(calendarDate, 'YYYY-MM-DD').add(-1, 'day').format('YYYY-MM-DD');

    dispatch(setDailyDate(prevDate));
  };
}

export const REQUEST_DAILY = 'REQUEST_DAILY';
export function requestDaily() {
  return {
    type: REQUEST_DAILY,
    payload: {
      loading: true,
      error: null,
    },
  };
}

export const RECEIVE_DAILY_SUCCESS = 'RECEIVE_DAILY_SUCCESS';
export function receiveDailySuccess(dates, count) {
  return {
    type: RECEIVE_DAILY_SUCCESS,
    payload: {
      dates,
      count,
      loading: false,
    },
  };
}

export const RECEIVE_DAILY_FAILURE = 'RECEIVE_DAILY_FAILURE';
export function receiveDailyFailure(error) {
  return {
    type: RECEIVE_DAILY_FAILURE,
    payload: {
      dates: [],
      count: 0,
      loading: false,
      error: { message: 'Unable to load daily data', description: error.message },
    },
  };
}

export function getDaily() {
  return (dispatch, getState) => {
    dispatch(requestDaily());

    const {
      calendarDate,
      searchText,
      page,
      pageSize,
    } = getState().daily;

    getSchedule(calendarDate, { search: searchText, limit: pageSize, offset: (page - 1) * pageSize })
      .then((obj) => {
        dispatch(receiveDailySuccess(obj.rows, obj.count));
      })
      .catch((err) => {
        dispatch(receiveDailyFailure(err));
      });
  };
}
