import React, { Component } from 'react';
import { connect } from 'react-redux';

import OverdueController from './OverdueController';

import {
  getOverdue,
} from '../redux/actions/overdueActions';

class OverduePage extends Component {
  componentDidMount() {
    this.props.onComponentDidMount();
  }

  render() {
    return <OverdueController />;
  }
}

const mapStateToProps = () => ({
});

const mapDispatchToProps = dispatch => ({
  onComponentDidMount() {
    dispatch(getOverdue());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OverduePage);
