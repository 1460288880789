import React, { Component } from 'react';
import { connect } from 'react-redux';

import DailyController from './DailyController';

import {
  getDaily,
} from '../redux/actions/dailyActions';

class DailyPage extends Component {
  componentDidMount() {
    this.props.onComponentDidMount();
  }

  render() {
    return <DailyController />;
  }
}

const mapStateToProps = () => ({
});

const mapDispatchToProps = dispatch => ({
  onComponentDidMount() {
    dispatch(getDaily());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DailyPage);
