import { combineReducers } from 'redux';

import client from './client';
import clientListing from './clientListing';
import daily from './daily';
import overdue from './overdue';
import planner from './planner';
import checkinModal from './checkinModal';
import breachModal from './breachModal';
import screeningModal from './screeningModal';
import forensicModal from './forensicModal';
import planningModal from './planningModal';
import batchPlanModal from './batchPlanModal';

const rootReducer = combineReducers({
  client,
  clientListing,
  daily,
  planner,
  overdue,

  breachModal,
  checkinModal,
  screeningModal,
  forensicModal,
  planningModal,
  batchPlanModal,
});

export default rootReducer;
