import React from 'react';
import moment from 'moment';
import { Form, Input, DatePicker, Button } from 'antd';

const OverdueFilter = (props) => {
  const {
    searchText,
    onSearchChange,
    onSearch,
    style,

    calendarDate,
    onDateChange,
    onDatePrevClick,
    onDateNextClick,
  } = props;

  return (
    <Form layout="inline" style={style}>
      {/*
      <Form.Item>
        <Button onClick={onDatePrevClick} icon="left" />
        <DatePicker allowClear={false} value={moment(calendarDate, 'YYYY-MM-DD')} onChange={onDateChange} />
        <Button onClick={onDateNextClick} icon="right" />
      </Form.Item>
      */}
      <Form.Item>
        <Input.Search placeholder="search last name" value={searchText} onChange={onSearchChange} onSearch={onSearch} />
      </Form.Item>
    </Form>
  );
};

export default OverdueFilter;
