import moment from 'moment';

import {
  getClient,
  upsertClient,
  getClientSchedule,
} from '../../../Common/api';
import history from '../../../Common/history';

export const CLIENT_LOADING_START = 'CLIENT_LOADING_START';
export const CLIENT_LOADING_SUCCESS = 'CLIENT_LOADING_SUCCESS';
export const CLIENT_LOADING_FAILURE = 'CLIENT_LOADING_FAILURE';
export const CLIENT_SCHEDULE_LOADING_START = 'CLIENT_SCHEDULE_LOADING_START';
export const CLIENT_SCHEDULE_LOADING_SUCCESS = 'CLIENT_SCHEDULE_LOADING_SUCCESS';
export const CLIENT_SCHEDULE_LOADING_FAILURE = 'CLIENT_SCHEDULE_LOADING_FAILURE';
export const CLIENT_SCHEDULE_SET_DATE = 'CLIENT_SCHEDULE_SET_DATE';
export const CLIENT_SCHEDULE_SET_TYPE = 'CLIENT_SCHEDULE_SET_TYPE';
export const CLIENT_SAVING_START = 'CLIENT_SAVING_START';
export const CLIENT_SAVING_SUCCESS = 'CLIENT_SAVING_SUCCESS';
export const CLIENT_SAVING_FAILURE = 'CLIENT_SAVING_FAILURE';
export const CLIENT_SET_FIELD = 'CLIENT_SET_FIELD';
export const CLIENT_DISCARD = 'CLIENT_DISCARD';

export function clientSetField(formState) {
  return {
    type: CLIENT_SET_FIELD,
    payload: {
      formState,
    },
  };
}

export function clientLoadingStart() {
  return {
    type: CLIENT_LOADING_START,
    payload: {
      isLoading: true,
      canSave: false,
      canDiscard: false,
      error: null,
    },
  };
}

export function clientLoadingSuccess(data) {
  const formState = {
    id: {
      value: data.id,
    },
    firstName: {
      value: data.firstName,
    },
    lastName: {
      value: data.lastName,
    },
    birthDate: {
      value: data.birthDate ? moment(data.birthDate, 'YYYY-MM-DD') : null,
    },
    phn: {
      value: data.phn,
    },

    phone: {
      value: data.phone,
    },
    email: {
      value: data.email,
    },
    picture: {
      value: data.picture,
    },
    streetAddressLine1: {
      value: data.streetAddressLine1,
    },
    streetAddressLine2: {
      value: data.streetAddressLine2,
    },
    city: {
      value: data.city,
    },
    province: {
      value: data.province,
    },
    postalCode: {
      value: data.postalCode,
    },

    companyName: {
      value: data.companyName,
    },
    companySupervisor: {
      value: data.companySupervisor,
    },
    companySupervisorPhone: {
      value: data.companySupervisorPhone,
    },
    companySupervisorEmail: {
      value: data.companySupervisorEmail,
    },
    contractStartDate: {
      value: data.contractStartDate ? moment(data.contractStartDate, 'YYYY-MM-DD') : null,
    },
    contractEndDate: {
      value: data.contractEndDate ? moment(data.contractEndDate, 'YYYY-MM-DD') : null,
    },
    contractNumber: {
      value: data.contractNumber,
    },
    defaultCheckinTimeStart: {
      value: data.defaultCheckinTimeStart ? moment(data.defaultCheckinTimeStart, 'HH') : null,
    },
    defaultCheckinTimeEnd: {
      value: data.defaultCheckinTimeEnd ? moment(data.defaultCheckinTimeEnd, 'HH') : null,
    },

    drugOfCompulsion: {
      value: data.drugOfCompulsion,
    },
    treatmentCentre: {
      value: data.treatmentCentre,
    },
    treatmentCentreStartDate: {
      value: data.treatmentCentreStartDate ? moment(data.treatmentCentreStartDate, 'YYYY-MM-DD') : null,
    },
    treatmentCentreEndDate: {
      value: data.treatmentCentreStartDate ? moment(data.treatmentCentreEndDate, 'YYYY-MM-DD') : null,
    },
    imePerformed: {
      value: data.imePerformed,
    },
    imePerformedBy: {
      value: data.imePerformedBy,
    },
    abstinentDate: {
      value: data.abstinentDate ? moment(data.abstinentDate, 'YYYY-MM-DD') : null,
    },
    abstinentDays: {
      value: data.abstinentDays,
    },
    additionalServices: {
      value: data.additionalServices,
    },

    notes: {
      value: data.notes,
    },
    archived: {
      value: data.archived,
    },
    statusActive: {
      value: !data.archived,
    },
  };

  return {
    type: CLIENT_LOADING_SUCCESS,
    payload: {
      isLoading: false,
      canSave: true,
      canDiscard: true,
      error: null,
      formState,
      data,
    },
  };
}

export function clientLoadingFailure(error) {
  return {
    type: CLIENT_LOADING_FAILURE,
    payload: {
      isLoading: false,
      canSave: true,
      canDiscard: true,
      error: {
        message: 'Unable to load client record',
        description: error.message,
      },
    },
  };
}

export function clientScheduleLoadingStart() {
  return {
    type: CLIENT_SCHEDULE_LOADING_START,
    payload: {
      isLoading: true,
      error: null,
    },
  };
}

export function clientScheduleLoadingSuccess(calendar) {
  return {
    type: CLIENT_SCHEDULE_LOADING_SUCCESS,
    payload: {
      isLoading: false,
      error: null,
      calendar,
    },
  };
}

export function clientScheduleSetDate(mom) {
  return {
    type: CLIENT_SCHEDULE_SET_DATE,
    payload: {
      date: mom,
    },
  };
}

export function clientScheduleNextMonth() {
  return (dispatch, getState) => {
    const {
      date,
    } = getState().client;
    const nextDate = moment(date, 'YYYY-MM-DD').startOf('month').add(1, 'month'); // .format('YYYY-MM-DD');
    dispatch(clientScheduleSetDate(nextDate));
  };
}
export function clientSchedulePrevMonth() {
  return (dispatch, getState) => {
    const {
      date,
    } = getState().client;
    const prevDate = moment(date, 'YYYY-MM-DD').startOf('month').add(-1, 'month'); // .format('YYYY-MM-DD');

    dispatch(clientScheduleSetDate(prevDate));
  };
}

export function clientScheduleSetType(calendarType) {
  return {
    type: CLIENT_SCHEDULE_SET_TYPE,
    payload: {
      calendarType,
    },
  };
}


export function clientLoad(clientId) {
  return (dispatch) => {
    dispatch(clientLoadingStart());

    getClient(clientId)
      .then((obj) => {
        dispatch(clientLoadingSuccess(obj));
      })
      .catch((err) => {
        dispatch(clientLoadingFailure(err.message));
      });

    dispatch(clientScheduleLoadingStart());
    getClientSchedule(clientId, {
      startDate: '2019-03-01',
      endDate: '2019-03-31',
    })
      .then((obj) => {
        dispatch(clientScheduleLoadingSuccess(obj.rows));
      })
      .catch((err) => {
        // TODO - problem if both throw an error
        dispatch(clientLoadingFailure(err.message));
      });
  };
}

export function clientScheduleLoad() {
  return (dispatch, getState) => {
    const clientId = getState().client.formState.id.value;

    const selectedMoment = getState().client.date;
    const startDate = moment(selectedMoment).startOf('month').format('YYYY-MM-DD');
    const endDate = moment(selectedMoment).endOf('month').format('YYYY-MM-DD');
    console.log('LOAD THIS SCHEDULE', startDate, endDate);

    dispatch(clientLoadingStart());

    getClientSchedule(clientId, {
      startDate,
      endDate,
    })
      .then((obj) => {
        dispatch(clientScheduleLoadingSuccess(obj.rows));
      })
      .catch((err) => {
        // TODO - problem if both throw an error
        dispatch(clientLoadingFailure(err.message));
      });
  };
}

export function clientSavingStart() {
  return {
    type: CLIENT_SAVING_START,
    payload: {
      isSaving: true,
      canSave: false,
      canDiscard: false,
      error: null,
    },
  };
}

export function clientSavingSuccess() {
  return {
    type: CLIENT_SAVING_SUCCESS,
    payload: {
      isSaving: false,
      canSave: true,
      canDiscard: true,
      error: null,
    },
  };
}

export function clientSavingFailure(error) {
  return {
    type: CLIENT_SAVING_FAILURE,
    payload: {
      isSaving: false,
      canSave: true,
      canDiscard: true,
      error: {
        message: 'Unable to save client record',
        description: error.message,
      },
    },
  };
}

export function clientSave(form) {
  return (dispatch, getState) => {
    // const { client } = getState();

    form.validateFields({
      force: true,
    }, (err, values) => {
      // If there are errors then cancel the save.
      // Any errors will be displayed per field on the form.
      if (err) {
        return;
      }

      dispatch(clientSavingStart());

      const data = Object.assign({}, values, {
        birthDate: values.birthDate ? values.birthDate.format('YYYY-MM-DD') : null,
        contractStartDate: values.contractStartDate ? values.contractStartDate.format('YYYY-MM-DD') : null,
        defaultCheckinTimeStart: values.defaultCheckinTimeStart ? values.defaultCheckinTimeStart.format('HH') : null,
        defaultCheckinTimeEnd: values.defaultCheckinTimeEnd ? values.defaultCheckinTimeEnd.format('HH') : null,
        treatmentCentreStartDate: values.treatmentCentreStartDate ? values.treatmentCentreStartDate.format('YYYY-MM-DD') : null,
        treatmentCentreEndDate: values.treatmentCentreEndDate ? values.treatmentCentreEndDate.format('YYYY-MM-DD') : null,
        abstinentDate: values.abstinentDate ? values.abstinentDate.format('YYYY-MM-DD') : null,
      });
      console.log('values', values);
      console.log('DATA', data);

      upsertClient(data)
        .then((obj) => {
          dispatch(clientSavingSuccess(obj));
          console.log(`Saved client id: ${obj.id}`);
          dispatch(clientLoad(obj.id));
          history.push(`/clients/${obj.id}`);
        })
        .catch((savErr) => {
          dispatch(clientSavingFailure(savErr));
        });
    });
  };
}

export function clientDiscard(clientId) {
  console.log(`clicked cancel on Client Form, id: ${clientId}`);
  return clientId ? clientLoad(clientId) : null;
  // return (dispatch, getState) => {
  //   dispatch(clientSavingStart());

  //   const { client } = getState();
  //   const { data } = client;

  //   upsertClient(data.id, data)
  //     .then((obj) => {
  //       dispatch(clientSavingFinish(obj));
  //     })
  //     .catch((err) => {
  //       dispatch(clientSavingFailure(err.message));
  //     });
}

// }
// export function breachModalSave(form) {
//   return (dispatch, getState) => {
//     const { breachModal } = getState();
//     const { onSave } = breachModal;

//     form.validateFields({ force: true }, (err, values) => {
//       // If there are errors then cancel the save.
//       // Any errors will be displayed per field on the form.
//       if (err) {
//         return;
//       }

//       const calendarDate = values.calendarDate.format('YYYY-MM-DD');

//       const {
//         clientId,
//         breachOccurred,
//         breachType,
//         breachNote,
//       } = values;

//       dispatch(breachModalSavingStart());

//       postBreach({ clientId, calendarDate }, { breachOccurred, breachType, breachNote })
//         .then((obj) => {
//           dispatch(breachModalSavingSuccess(obj));
//           dispatch(breachModalClose());
//           onSave();
//         })
//         .catch((apiErr) => {
//           dispatch(breachModalSavingFailure(apiErr));
//         });
//     });
//   };
// }
