import React from 'react';
import { Col, Row } from 'antd';

import Page from '../Common/Page';
import ChangePasswordCard from './ChangePasswordCard';

const ProfilePage = ({
  changePasswordProcessing,
  changePasswordError,
  changePasswordSuccess,
  onChangePasswordClick,
}) => (
  <Page title="Profile">
    <Row>
      <Col xs={24} sm={18} md={12} lg={8} xl={6}>
        <ChangePasswordCard
          processing={changePasswordProcessing}
          error={changePasswordError}
          success={changePasswordSuccess}
          onChangePasswordClick={onChangePasswordClick}
        />
      </Col>
    </Row>
  </Page>
);

export default ProfilePage;
