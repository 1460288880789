import React from 'react';
import FormModal from '../../Components/FormModal';
import BatchPlanForm from './BatchPlanForm';

const BatchPlanFormModal = props => (
  <FormModal title="BatchPlan" {...props} width="800">
    <BatchPlanForm {...props} />
  </FormModal>
);

export default BatchPlanFormModal;
