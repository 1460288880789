import { connect } from 'react-redux';

import ForensicModal from './ForensicModal';

import {
  forensicModalClose,
  forensicModalSave,
  forensicModalSetField,
} from '../../redux/actions/forensicActions';

const mapStateToProps = state => ({
  ...state.forensicModal,
});

const mapDispatchToProps = dispatch => ({
  onCancel() {
    dispatch(forensicModalClose());
  },
  onSave(form) {
    dispatch(forensicModalSave(form));
  },
  onFieldChange(formState) {
    dispatch(forensicModalSetField(formState));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ForensicModal);
