import React, { Component } from 'react';
import { connect } from 'react-redux';

import ClientListingController from './ClientListingController';

import {
  getClientListing,
} from '../redux/actions/clientListingActions';

class ClientListingControllerWithData extends Component {
  componentDidMount() {
    this.props.onComponentDidMount();
  }

  render() {
    return <ClientListingController />;
  }
}

const mapStateToProps = () => ({
});

const mapDispatchToProps = dispatch => ({
  onComponentDidMount() {
    dispatch(getClientListing());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ClientListingControllerWithData);
