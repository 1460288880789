import React from 'react';
import { Alert, Button, Spin } from 'antd';
import moment from 'moment';
import time24to12 from '../../Common/time24to12';

const CheckinCardLoading = () => (
  <Spin spinning><Alert type="success" description="" /></Spin>
);

const CheckinCardNotRequired = () => {
  const desc = (
    <span>
      <p>You do not have to checkin today.</p>
    </span>
  );
  return <Alert type="success" showIcon message="Checkin Not Required" description={desc} closable={false} />;
};

const CheckinCardMissed = ({ phone }) => {
  const desc = (
    <span>
      <p>You missed your checkin today. Please contact the office at <a href={`tel:+1${phone}`}>{phone}</a></p>
    </span>
  );
  return <Alert type="error" showIcon message="Checkin Missed" description={desc} closable={false} />;
};

const CheckinCardPending = ({ record, onCheckinClick }) => {
  const desc = (
    <span>
      <p>{`Please checkin between ${time24to12(record.checkinStartTime)} and ${time24to12(record.checkinEndTime)}.`}</p>
    </span>
  );
  return <Alert type="info" showIcon message="Checkin Required" description={desc} closeText="Check-In" closable={false} onClose={onCheckinClick} />;
};

const CheckinCardError = ({ error, onCheckinClick }) => {
  const desc = (
    <span>
      <p>{error}</p>
      {/* <p>A technical error has occured. You must contact the office at XXX-XXX-XXXX to check-in.</p> */}
    </span>
  );
  return <Alert type="error" showIcon description={desc} closeText="Retry" closable={false} onClose={onCheckinClick} />;
};

const CheckinCardSampleRequired = ({ record }) => {
  const { calendarDate, sampleRequired, sampleInstructions, sampleStartTime, sampleEndTime, sampleNote } = record;

  const message = (
    <span>
      {/* You must provide a urine sample today.
      <br /> */}
      <b>Date:</b>
      {' '}
      {moment(calendarDate).format('dddd, MMMM Do, YYYY')}
      {' '}
      <br />
      <b>Time:</b>
      {' '}
      {time24to12(sampleStartTime)}
      {' '}
      to
      {' '}
      {time24to12(sampleEndTime)}
      {' '}
      <br />
      <b>Instructions:</b>
      {' '}
      {sampleInstructions}
    </span>
  );

  return <Alert type="warning" showIcon message="Sample Required Today" description={message} />;
};

const CheckinCardSampleNotRequired = ({ record }) => (
  <Alert
    description="Your check-in was successful and you do not have to provide a urine sample today."
    type="success"
    showIcon
    message="Sample Not Required"
  />
);
const CheckinCardError2 = ({ error }) => (
  <Alert
    description={error}
    type="error"
  />
);

const CheckinCard = ({
  onCheckinClick,
  record,
  loading,
  error,
}) => {
  console.log('RECORD', record);
  if (loading) {
    return <CheckinCardLoading />;
  }

  if (error) {
    return <CheckinCardError error={error} onCheckinClick={onCheckinClick} />;
  }

  if (record) {
    const { checkinRequired, checkinReceived, checkinMissed, checkinError, sampleRequired } = record;

    if (checkinError) {
      return <CheckinCardError2 error={checkinError} onCheckinClick={onCheckinClick} />;
    }

    if (!checkinRequired) {
      return <CheckinCardNotRequired />;
    }

    if (checkinMissed) {
      return <CheckinCardMissed record={record} onCheckinClick={onCheckinClick} phone={process.env.REACT_APP_CONTACT_PHONE} />;
    }

    if (!checkinReceived) {
      return <CheckinCardPending record={record} onCheckinClick={onCheckinClick} />;
    }
    if (sampleRequired) {
      return <CheckinCardSampleRequired record={record} />;
    }
    return <CheckinCardSampleNotRequired record={record} />;
  }

  return null;
};

export default CheckinCard;
