import {
  BATCH_PLAN_MODAL_OPEN,
  BATCH_PLAN_MODAL_CLOSE,
  BATCH_PLAN_MODAL_SAVING_START,
  BATCH_PLAN_MODAL_SAVING_SUCCESS,
  BATCH_PLAN_MODAL_SAVING_FAILURE,
  BATCH_PLAN_MODAL_SET_FIELD,
} from '../actions/batchPlanActions';

const initialState = {
  formState: {},
  isProcessing: false,
  error: null,
  show: false,
  canSave: false,
  canCancel: true,
};

export default function planModal(state = initialState, action) {
  switch (action.type) {
    case BATCH_PLAN_MODAL_OPEN:
    case BATCH_PLAN_MODAL_CLOSE:
    case BATCH_PLAN_MODAL_SAVING_START:
    case BATCH_PLAN_MODAL_SAVING_SUCCESS:
    case BATCH_PLAN_MODAL_SAVING_FAILURE:
      return Object.assign({}, state, { ...action.payload });
    case BATCH_PLAN_MODAL_SET_FIELD:
      return Object.assign({}, state, {
        formState: Object.assign({}, state.formState, { ...action.payload.formState }),
      });
    default:
      return state;
  }
}
