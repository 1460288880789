import React from 'react';
import { DatePicker, Form, Input, Button, Select, Row, Col, Checkbox, Alert } from 'antd';

const ScreeningForm = (props) => {
  const { canSave, canCancel, onSave, onCancel, isProcessing, form } = props;
  const { getFieldDecorator } = form;

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  const { formState: { sampleRequired: { value: sampleRequired } } } = props;

  return (
    <Form layout="vertical">
      {!sampleRequired && <Alert type="info" message="A Sample was not required on this day, so a Screening Result cannot be selected." showIcon style={{ marginBottom: '16px'}} ></Alert> }
      <Form.Item label="Client" {...formItemLayout}>
        {getFieldDecorator('clientName', {
        })(
          <Input disabled />,
        )}
      </Form.Item>
      <Form.Item label="Client Id" {...formItemLayout} style={{ display: 'none' }}>
        {getFieldDecorator('clientId', {
        })(
          <Input disabled />,
        )}
      </Form.Item>
      <Form.Item label="Date" {...formItemLayout}>
        {getFieldDecorator('calendarDate', {
        })(
          <DatePicker disabled />,
        )}
      </Form.Item>
      <Form.Item label="Sample Required" {...formItemLayout} style={{ display: 'none' }}>
        {getFieldDecorator('sampleRequired', {
          valuePropName: 'checked'
        })(
          <Checkbox />,
        )}
      </Form.Item>
      <Form.Item label="Screening Lab Result" {...formItemLayout}>
        {getFieldDecorator('screeningResult', {
          rules: [{
            // required: screeningRequired,
            // message: 'Breach Type must be specified if Breach Occurred.',
          }],
        })(
          <Select disabled={!sampleRequired}>
            <Select.Option value="NULL">&nbsp;</Select.Option>
            <Select.Option value="Negative">Negative</Select.Option>
            <Select.Option value="Non-Negative">Non-Negative</Select.Option>
          </Select>,
        )}
      </Form.Item>
      <Row>
        <Col span={24} style={{ textAlign: 'right' }}>
          <Button
            icon="rollback"
            onClick={onCancel}
            disabled={!canCancel}
          >
            Cancel
          </Button>
          <Button
            style={{ marginLeft: 8 }}
            type="primary"
            icon="save"
            loading={isProcessing}
            onClick={() => onSave(props.form)}
            disabled={!canSave || !sampleRequired}
          >
            Save
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default Form.create({
  name: 'ScreeningForm',
  mapPropsToFields(props) {
    return {
      clientId: Form.createFormField(props.formState.clientId),
      clientName: Form.createFormField(props.formState.clientName),
      calendarDate: Form.createFormField(props.formState.calendarDate),
      sampleRequired: Form.createFormField(props.formState.sampleRequired),
      screeningResult: Form.createFormField(props.formState.screeningResult),
    };
  },
  onFieldsChange(props, formState) {
    props.onFieldChange(formState);
  },
})(ScreeningForm);
