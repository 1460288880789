import { listClients } from '../../../Common/api';

export const SET_CLIENT_LISTING_SEARCH_TEXT = 'SET_CLIENT_LISTING_SEARCH_TEXT';
export function setClientListingSearchText(searchText) {
  return {
    type: SET_CLIENT_LISTING_SEARCH_TEXT,
    payload: {
      searchText,
      page: 1,
    },
  };
}

export const SET_CLIENT_LISTING_STATUS = 'SET_CLIENT_LISTING_STATUS';
export function setClientListingStatus(status) {
  return {
    type: SET_CLIENT_LISTING_STATUS,
    payload: {
      status,
      page: 1,
    },
  };
}

export const SET_CLIENT_LISTING_PAGE = 'SET_CLIENT_LISTING_PAGE';
export function setClientListingPage(page) {
  return {
    type: SET_CLIENT_LISTING_PAGE,
    payload: {
      page,
    },
  };
}

export const SET_CLIENT_LISTING_PAGE_SIZE = 'SET_CLIENT_LISTING_PAGE_SIZE';
export function setClientListingPageSize(pageSize) {
  return {
    type: SET_CLIENT_LISTING_PAGE_SIZE,
    payload: {
      pageSize,
      page: 1,
    },
  };
}

export const REQUEST_CLIENT_LISTING = 'REQUEST_CLIENT_LISTING';
export function requestClientListing() {
  return {
    type: REQUEST_CLIENT_LISTING,
    payload: {
      loading: true,
      error: null,
    },
  };
}

export const RECEIVE_CLIENT_LISTING_SUCCESS = 'RECEIVE_CLIENT_LISTING_SUCCESS';
export function receiveClientListingSuccess(clients, count) {
  return {
    type: RECEIVE_CLIENT_LISTING_SUCCESS,
    payload: {
      clients,
      count,
      loading: false,
    },
  };
}

export const RECEIVE_CLIENT_LISTING_FAILURE = 'RECEIVE_CLIENT_LISTING_FAILURE';
export function receiveClientListingFailure(error) {
  return {
    type: RECEIVE_CLIENT_LISTING_FAILURE,
    payload: {
      clients: [],
      count: 0,
      page: null,
      loading: false,
      error: { message: 'Unable to load client listing', description: error.message },
    },
  };
}

export function getClientListing() {
  // Note that the function also receives getState()
  // which lets you choose what to dispatch next.

  // This is useful for avoiding a network request if
  // a cached value is already available.

  return (dispatch, getState) => {
    dispatch(requestClientListing());

    const {
      searchText,
      status,
      pageSize,
      page,
    } = getState().clientListing;

    // { search: this.state.searchText, status: this.state.status, limit: this.state.limit, offset: this.state.offset }
    listClients({ search: searchText, status, limit: pageSize, offset: (page - 1) * pageSize })
      .then((obj) => {
        dispatch(receiveClientListingSuccess(obj.rows, obj.count));
      })
      .catch((err) => {
        dispatch(receiveClientListingFailure(err));
      });
  };
}
