import React from 'react';
import FormModal from '../../Components/FormModal';
import ScreeningForm from './ScreeningForm';

const ScreeningModal = props => (
  <FormModal title="Screening" {...props}>
    <ScreeningForm {...props} />
  </FormModal>
);

export default ScreeningModal;
