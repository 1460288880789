import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';

import './index.css';
import AppController from './AppController';
import rootReducer from './redux/reducers';

const logger = store => next => (action) => {
  console.log('dispatching', action);
  const result = next(action);
  console.log('next state', store.getState());
  return result;
};

const crashReporter = store => next => (action) => {
  try {
    return next(action);
  } catch (err) {
    console.error('Caught an exception!', err);
    // Raven.captureException(err, {
    //   extra: {
    //     action,
    //     state: store.getState()
    //   }
    // })
    throw err;
  }
};


const store = createStore(rootReducer, applyMiddleware(
  thunkMiddleware,
  logger,
  crashReporter,
));

export default (props) => {

  return (
    <Provider store={store}>
      <AppController {...props} />
    </Provider>
  );
}
