import React from 'react';
import FormModal from '../../Components/FormModal';
import CheckinForm from './CheckinForm';

const CheckinModal = props => (
  <FormModal title="Checkin" {...props}>
    <CheckinForm {...props} />
  </FormModal>
);

export default CheckinModal;
