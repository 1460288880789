import moment from 'moment';

import {
  getOverdue as getOverdueApi,
} from '../../../Common/api';

export const SET_OVERDUE_SEARCH_TEXT = 'SET_OVERDUE_SEARCH_TEXT';
export function setOverdueSearchText(searchText) {
  return {
    type: SET_OVERDUE_SEARCH_TEXT,
    payload: {
      searchText,
      page: 1,
    },
  };
}

export const SET_OVERDUE_PAGE = 'SET_OVERDUE_PAGE';
export function setOverduePage(page) {
  return {
    type: SET_OVERDUE_PAGE,
    payload: {
      page,
    },
  };
}

export const SET_OVERDUE_PAGE_SIZE = 'SET_OVERDUE_PAGE_SIZE';
export function setOverduePageSize(pageSize) {
  return {
    type: SET_OVERDUE_PAGE_SIZE,
    payload: {
      pageSize,
      page: 1,
    },
  };
}

export const SET_OVERDUE_DATE = 'SET_OVERDUE_DATE';
export function setOverdueDate(calendarDate) {
  // Dont set the date if it is was invalid.
  if (!moment(calendarDate, 'YYYY-MM-DD').isValid()) {
    return { type: 'CANCEL_ACTION', payload: SET_OVERDUE_DATE };
  }
  return {
    type: SET_OVERDUE_DATE,
    payload: {
      calendarDate,
      page: 1,
    },
  };
}

export function nextOverdueDate() {
  return (dispatch, getState) => {
    const { calendarDate } = getState().overdue;
    const nextDate = moment(calendarDate, 'YYYY-MM-DD').add(1, 'day').format('YYYY-MM-DD');
    dispatch(setOverdueDate(nextDate));
  };
}
export function prevOverdueDate() {
  return (dispatch, getState) => {
    const { calendarDate } = getState().overdue;
    const prevDate = moment(calendarDate, 'YYYY-MM-DD').add(-1, 'day').format('YYYY-MM-DD');

    dispatch(setOverdueDate(prevDate));
  };
}

export const REQUEST_OVERDUE = 'REQUEST_OVERDUE';
export function requestOverdue() {
  return {
    type: REQUEST_OVERDUE,
    payload: {
      loading: true,
      error: null,
    },
  };
}

export const RECEIVE_OVERDUE_SUCCESS = 'RECEIVE_OVERDUE_SUCCESS';
export function receiveOverdueSuccess(dates, count) {
  return {
    type: RECEIVE_OVERDUE_SUCCESS,
    payload: {
      dates,
      count,
      loading: false,
    },
  };
}

export const RECEIVE_OVERDUE_FAILURE = 'RECEIVE_OVERDUE_FAILURE';
export function receiveOverdueFailure(error) {
  return {
    type: RECEIVE_OVERDUE_FAILURE,
    payload: {
      dates: [],
      count: 0,
      loading: false,
      error: { message: 'Unable to load overdue data', description: error.message },
    },
  };
}

export function getOverdue() {
  return (dispatch, getState) => {
    dispatch(requestOverdue());

    const {
      // calendarDate,
      searchText,
      page,
      pageSize,
    } = getState().overdue;

    getOverdueApi({ search: searchText, limit: pageSize, offset: (page - 1) * pageSize })
      .then((obj) => {
        dispatch(receiveOverdueSuccess(obj.rows, obj.count));
      })
      .catch((err) => {
        dispatch(receiveOverdueFailure(err));
      });
  };
}
