import React from 'react';
import { Select } from 'antd';

const StatusSelect = props => (
  <Select {...props}>
    <Select.Option value="active">Active Only</Select.Option>
    <Select.Option value="archived">Archived Only</Select.Option>
    <Select.Option value="all">Active or Archived</Select.Option>
  </Select>
);

export default StatusSelect;
