import { Icon, Menu } from 'antd';
import React from 'react';
import { NavLink } from 'react-router-dom';

const AppMenu = () => (
  <Menu mode="horizontal">
    <Menu.Item key="home">
      <NavLink to="/home">
        <Icon type="home" className="menu-icon" />
      </NavLink>
    </Menu.Item>
    <Menu.Item key="contact">
      <NavLink to="/contact">
        <Icon type="info-circle-o" className="menu-icon" />
      </NavLink>
    </Menu.Item>
    <Menu.Item key="profile">
      <NavLink to="/profile">
        <Icon type="user" className="menu-icon" />
      </NavLink>
    </Menu.Item>
    <Menu.Item key="logout">
      <NavLink to="/logout">
        <Icon type="logout" className="menu-icon" />
      </NavLink>
    </Menu.Item>
  </Menu>
);

export default AppMenu;
