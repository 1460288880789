import React from 'react';
import { Popover, Icon } from 'antd';
import time24to12 from '../../Common/time24to12';


const CHECKIN_NOT_NEEDED = 'Not Needed';
const CHECKIN_COMPLETE = 'Complete';
const CHECKIN_MISSED = 'Missed';
const CHECKIN_PENDING = 'Pending';
const CHECKIN_LATE = 'Late';

const CheckinCell = (props) => {
  const {
    checkinStatus,
    checkinNotRequiredReason,
    checkinTime,
    checkinMissedTime,
    checkinStartTime,
    checkinEndTime,
    checkinSource,
    showLabel,
  } = props;

  let content;
  let icon;

  switch (checkinStatus) {
    case CHECKIN_COMPLETE:
      content = `Completed at ${time24to12(checkinTime)} via ${checkinSource}`;
      icon = <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" />;
      break;
    case CHECKIN_LATE:
      content = `Checkin received late at ${time24to12(checkinTime)}`;
      icon = <Icon type="clock-circle" theme="twoTone" twoToneColor="orange" />;
      break;
    case CHECKIN_MISSED:
      content = `Missed. Marked as missed at ${time24to12(checkinMissedTime)}`;
      icon = <Icon type="close-circle" theme="twoTone" twoToneColor="red" />;
      break;
    case CHECKIN_PENDING:
      content = `Pending: Checkin required between ${time24to12(checkinStartTime)} and ${time24to12(checkinEndTime)}`;
      icon = <Icon type="question-circle" />;
      break;
    case CHECKIN_NOT_NEEDED:
      content = `Not Needed. ${checkinNotRequiredReason}`;
      icon = <Icon type="stop" />;
      break;
    default:
      throw new Error(`Unexpected screeningStatus: ${checkinStatus}`);
  }

  const label = showLabel ? (icon ? <><span>Ch</span><br /></> : <><span></span><br /></>) : null;

  return (
    <Popover title="Checkin" content={content} trigger="click">
      {label}
      {icon}
    </Popover>
  );
};

export default CheckinCell;
