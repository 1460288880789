import React, { Component } from 'react';

import Messages from './Messages';

class MessagesController extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sendMessageText: '', // The current text that the user has typed to send a message.
      sendMessageLoading: false, // Are we currently sending a message?
      sendMessageError: null, // An error occured while trying to send a message.

      messages: [], // All of the messages we have loaded.
      messagesLoading: false, // Are we currently loading the initial messages.
      messagesError: null, // An error occured while trying to load the initial messages.

      moreKey: null, // The key that must be passed back to the api to load more messages.
      moreMessagesLoading: false, // Are we currently loading more messages?
      moreMessagesError: null, // An error occured while trying to load more messages.
    };

    this.onSendMessageClick = this.onSendMessageClick.bind(this);
    this.onSendMessageTextChange = this.onSendMessageTextChange.bind(this);
    this.onMessagesLoadMoreClick = this.onMessagesLoadMoreClick.bind(this);
  }
  componentDidMount() {
    this.loadMessages();
  }
  onMessagesLoadMoreClick() {
    const { moreKey } = this.state;

    this.setState({
      moreMessagesLoading: true,
      moreKey: null, // Dont allow users to load more while already loading more.
    }, () => {
      this.props.onGetMoreMessages(moreKey, (err, res) => {
        if (err) {
          this.setState({
            moreMessagesError: err,
            moreMessagesLoading: false,
            moreKey, // Let the user try again on failure
          });
        } else {
          this.setState({
            moreMessagesError: null,
            moreMessagesLoading: false,
            messages: res.messages.concat(this.state.messages),
            moreKey: res.more,
          });
        }
      });
    });
  }
  onSendMessageClick() {
    // No message. Dont try and send.
    if (!this.state.sendMessageText || this.state.sendMessageText.length === 0) {
      return;
    }

    this.setState({
      sendMessageLoading: true,
    }, () => {
      this.props.onSendMessage(this.state.sendMessageText, (err, res) => {
        if (err) {
          this.setState({
            sendMessageError: err,
            sendMessageLoading: false,
          });
        } else {
          this.setState({
            sendMessageError: null,
            sendMessageText: '',
            sendMessageLoading: false,
            messages: this.state.messages.concat([res]),
          });
        }
      });
    });
  }
  onSendMessageTextChange(e) {
    this.setState({
      sendMessageText: e.target.value,
    });
  }
  loadMessages() {
    this.setState({
      messagesLoading: true,
    }, () => {
      this.props.onGetMessages((err, res) => {
        if (err) {
          this.setState({
            messagesError: err,
            messagesLoading: false,
          });
        } else {
          this.setState({
            messagesError: null,
            messagesLoading: false,
            messages: res.messages,
            moreKey: res.more,
          });
        }
      });
    });
  }
  render() {
    return (
      <Messages

        // Send message
        sendMessageText={this.state.sendMessageText}
        sendMessageLoading={this.state.sendMessageLoading}
        sendMessageError={this.state.sendMessageError}
        onSendMessageClick={this.onSendMessageClick}
        onSendMessageTextChange={this.onSendMessageTextChange}

        // Initial messages
        messages={this.state.messages}
        messagesLoading={this.state.messagesLoading}
        messagesError={this.state.messagesError}

        // More messages
        moreMessagesLoading={this.state.moreMessagesLoading}
        moreMessagesError={this.state.moreMessagesError}
        canLoadMore={this.state.moreKey}
        onLoadMoreClick={this.onMessagesLoadMoreClick}
      />
    );
  }
}

export default MessagesController;
