import moment from 'moment';

import {
  SET_DAILY_SEARCH_TEXT,
  SET_DAILY_PAGE,
  SET_DAILY_PAGE_SIZE,
  SET_DAILY_DATE,
  REQUEST_DAILY,
  RECEIVE_DAILY_SUCCESS,
  RECEIVE_DAILY_FAILURE,
} from '../actions/dailyActions';

const initialState = {
  searchText: '',
  loading: false,
  error: '',
  calendarDate: moment().format('YYYY-MM-DD'),
  dates: [],
  pageSize: 10,
  page: 1,
  count: 0,
};

export default function daily(state = initialState, action) {
  switch (action.type) {
    case SET_DAILY_SEARCH_TEXT:
    case SET_DAILY_DATE:
    case SET_DAILY_PAGE:
    case SET_DAILY_PAGE_SIZE:
    case REQUEST_DAILY:
    case RECEIVE_DAILY_SUCCESS:
    case RECEIVE_DAILY_FAILURE:
      return Object.assign({}, state, { ...action.payload });
    default:
      return state;
  }
}
