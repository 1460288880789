import React from 'react';
import { Card } from 'antd';

const AddressCard = ({
  company,
  addressLine1,
  addressLine2,
  addressLine3,
  mapUrl,
}) => (
  <Card>
    <div>
      <span className="highlight">Address:</span>
      <p>{company}<br />{addressLine1}<br />{addressLine2}<br />{addressLine3}</p>
    </div>
    <div>
      <a target="_blank" rel="noopener noreferrer" href={mapUrl}>Open Map</a>
    </div>
  </Card>
);

export default AddressCard;
