import moment from 'moment';

import {
  CLIENT_LOADING_START,
  CLIENT_LOADING_SUCCESS,
  CLIENT_LOADING_FAILURE,
  CLIENT_SAVING_START,
  CLIENT_SAVING_SUCCESS,
  CLIENT_SAVING_FAILURE,
  CLIENT_SET_FIELD,
  CLIENT_DISCARD,

  CLIENT_SCHEDULE_LOADING_START,
  CLIENT_SCHEDULE_LOADING_SUCCESS,
  CLIENT_SCHEDULE_LOADING_FAILURE,
  CLIENT_SCHEDULE_SET_DATE,
  CLIENT_SCHEDULE_SET_TYPE,

} from '../actions/clientActions';

const initialState = {
  formState: {},
  data: {},
  isSaving: false,
  isLoading: false,
  error: null,
  canSave: false,
  canDiscard: true,
  date: moment(), // '2019-03-01', 'YYYY-MM-DD', true),
  calendar: [],
  calendarType: 'Calendar',
  // calendarDatesLoading: false,
};

export default function client(state = initialState, action) {
  switch (action.type) {
    case CLIENT_SET_FIELD:
      return Object.assign({}, state, {
        formState: Object.assign({}, state.formState, { ...action.payload.formState }),
      });
    case CLIENT_SAVING_START:
      return Object.assign({}, state, { ...action.payload });
    case CLIENT_SAVING_SUCCESS:
      return Object.assign({}, state, { ...action.payload });
    case CLIENT_SAVING_FAILURE:
      return Object.assign({}, state, { ...action.payload });
    case CLIENT_LOADING_START:
      return Object.assign({}, state, { ...action.payload });
    case CLIENT_LOADING_SUCCESS:
      return Object.assign({}, state, { ...action.payload });
    case CLIENT_LOADING_FAILURE:
      return Object.assign({}, state, { ...action.payload });
    case CLIENT_SCHEDULE_LOADING_START:
      return Object.assign({}, state, { ...action.payload });
    case CLIENT_SCHEDULE_LOADING_SUCCESS:
      return Object.assign({}, state, { ...action.payload });
    case CLIENT_SCHEDULE_LOADING_FAILURE:
      return Object.assign({}, state, { ...action.payload });
    case CLIENT_SCHEDULE_SET_DATE:
      return Object.assign({}, state, { ...action.payload });
    case CLIENT_SCHEDULE_SET_TYPE:
      return Object.assign({}, state, { ...action.payload });
    case CLIENT_DISCARD:
      return Object.assign({}, state, { ...action.payload });
    default:
      return state;
  }
}
