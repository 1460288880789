import React from 'react';
import { Layout } from 'antd';

import AppMenu from './AppMenu';

const { Footer, Content } = Layout;

const AppView = ({ children }) => (
  <Layout className="top-layout">
    <Content className="content-layout">
      {children}
    </Content>
    <Footer>
      <AppMenu />
    </Footer>
  </Layout>
);

export default AppView;
