import React, { Component } from 'react';
import { connect } from 'react-redux';

import ClientController from './ClientController';
import { clientLoad, clientLoadingSuccess } from '../redux/actions/clientActions';

class ClientPage extends Component {
  componentDidMount() {
    const clientId = this.props.match.params.id;
    console.log('In didmount', clientId);

    if (clientId !== 'new') {
      this.props.clientLoad(clientId);
    } else {
      this.props.clientLoadingSuccess({});
    }
  }

  render() {
    return <ClientController />;
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  clientLoad(clientId) {
    dispatch(clientLoad(clientId));
  },
  clientLoadingSuccess(data) {
    dispatch(clientLoadingSuccess(data));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ClientPage);
