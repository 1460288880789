import React from 'react';
import { Button, Icon, Input, List, Alert } from 'antd';
import moment from 'moment';

import Page from '../Common/Page';


const Messages = ({
  sendMessageText,
  sendMessageLoading,
  sendMessageError,
  onSendMessageClick,
  onSendMessageTextChange,

  messages,
  messagesLoading,
  messagesError,

  moreMessagesLoading,
  moreMessagesError,
  canLoadMore,
  onLoadMoreClick,
}) => (
    <Page title="Messages">

          {moreMessagesError && <Alert message={moreMessagesError} type="error" />}
          {canLoadMore && <Button onClick={onLoadMoreClick}>Load Older</Button>}
          {moreMessagesLoading && <Icon type="loading" />}
          <List
            loading={messagesLoading}
            dataSource={messages}
            locale={{
              emptyText: messagesError ?
                <Alert message={messagesError} type="error" /> :
                <span>You have no messages.</span>,
            }}
            renderItem={item => (
              <List.Item>
                <List.Item.Meta description={item.message} title={moment(item.date).calendar()} />
              </List.Item>
            )}
          />
          {sendMessageError && <Alert message={sendMessageError} type="error" />}
          <Input.Search
            disabled={sendMessageLoading}
            placeholder="Send Message"
            enterButton={sendMessageLoading ? <Icon type="loading" /> : <Icon type="arrow-up" />}
            onSearch={onSendMessageClick}
            onChange={onSendMessageTextChange}
            value={sendMessageText}
              />

  </Page>
);

export default Messages;
