import React from 'react';
import FormModal from '../../Components/FormModal';
import PlanningForm from './PlanningForm';

const PlanningModal = props => (
  <FormModal title="Planning" {...props} width="800">
    <PlanningForm {...props} />
  </FormModal>
);

export default PlanningModal;
